.navigation {
  margin-top: 4rem;
  margin-bottom: 6rem;
  @include respond-to(small) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  &__list {
    margin-bottom: 2.9rem;
    @include respond-to(medium) {
      margin-bottom: 2.1rem;
    }
    @include respond-to(small) {
      margin-top: 3rem;
    }
    &__item {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &__cta {
    text-decoration: none;
    color: $c-primary;
    letter-spacing: .27rem;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0 2rem;
    @include respond-to(medium) {
      margin: 0 1rem;
    }
    @include respond-to(xsmall) {
      margin: 0 .5rem;
      letter-spacing: .1rem;
    }
    transition: opacity .2s ease-in-out;
    &:hover,
    &:focus {
      opacity: .7;
    }
  
    &:before {
      content: '';
      position: absolute;
      top: calc(100% + 1.5rem);
      left: 0;
      right: 0;
      margin: auto;
      width: .3rem;
      height: .7rem;
      background: $c-grey-1;
      opacity: 0;
      @extend %anim2;
    }
    &:hover,
    &:focus {
      &:before {
        opacity: 1;
        top: calc(100% + 1rem);
      }
    }
    &--is-selected {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: calc(100% + 1rem);
        left: 0;
        right: 0;
        margin: auto;
        width: .3rem;
        height: .7rem;
        background: $c-primary !important;
        opacity: 1;
      }
    }
  }

  &__logo {
    display: block;
    margin: auto;
    text-align: center;
    @include respond-to(medium) {
      max-width: 25rem;
    }
    &__img {
      display: inline-block;
      max-width: 100%;
    }
  }
}
