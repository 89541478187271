.title {
  font-size: 3.8rem;
  line-height: 1.3;
  color: $c-primary;
  font-weight: 500;
  text-transform: uppercase; 
  letter-spacing: .15rem;

  @include respond-to(medium) {
    font-size: 3.2rem;
  }
  @include respond-to(small) {
    font-size: 3rem;
  }
  @include respond-to(xsmall) {
    font-size: 2.5rem;
  }

  &--stroked {
    position: relative;
    z-index: 2;
    &:before {
      content: '';
      position: absolute;
      background: $c-grey-3;
      width: 13rem;
      height: .1rem;
      @include respond-to(small) {
        display: none;
      }
    }
    &--left:before {
      bottom: -.7rem;
      right: calc(100% - 2.75rem);
    }
    &--right:before {
      bottom: -.7rem;
      left: calc(100% - 2.75rem);
    }
    &--top:before {
      bottom: calc(100% + 5.5rem);
      left: 7.5rem;
      width: .1rem;
      height: 13rem;
    }
  }

  &--small {
    font-size: 1.6rem;
    @include respond-to(small) {
      font-size: 1.4rem;
    }
  }
}