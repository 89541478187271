@import "iconfont/iconfont";

.u-typography {
  p {
    display: block;
    font-family: $f-secondary;
    font-size: 1.3rem;
    font-weight: 400;
    color: $c-primary;
    line-height: 1.6;
    font-style: italic;
  }

  strong {
    font-weight: 500;
  }

  a {
    font-weight: 500;
    color: $c-primary;
    text-decoration-skip: ink;
  }
  > *:not(:last-child) {
    margin-bottom: 2rem;
    @include respond-to(small) {
      margin-bottom: 1rem;
    }
  }

  ul {
    li {
      margin-left: 1.8rem;
      line-height: 1.8;
      font-size: 1.3rem;
    }
  }

  blockquote {
    p {
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.05rem;
      color: $c-primary;
      font-size: 1.3rem;
      font-weight: 300;
      font-style: normal;
      font-family: $f-primary;
      @include respond-to(xsmall) {
        font-size: 1.2rem;
      }
    }
    br {
      display: none;
    }
    footer {
      margin-top: 1rem;
      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 2.1rem;
        height: .1rem;
        background: rgba($c-grey-2, .4);
        margin-right: 1rem;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.1rem;
        font-family: $f-secondary;
        color: $c-primary;
        font-style: italic;
        letter-spacing: .05rem;
      }
    }
  }
}

.u-text-tertiary {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05rem;
  color: $c-grey-2;
  font-size: 1.1rem;
  font-weight: 300;
}