.prev-next-buttons {
  display: flex;
  justify-content: space-between;
  &__previous, &__next {
    display: flex;
    align-items: center;
    text-decoration: none;
    @extend %anim2;
    &:hover,
    &:focus {
      opacity: .7;
    }
  }
  &__next {
    text-align: right;
  } 
  &__previous & {
    &__icon {
      margin-right: 2rem;
      @include respond-to(medium) {
        margin-right: 3rem;
      }
      @include respond-to(small) {
        margin-right: 2rem;
      }
    }
  }
  &__next & {
    &__icon {
      margin-left: 2rem;
      @include respond-to(medium) {
        margin-left: 3rem;
      }
      @include respond-to(small) {
        margin-left: 2rem;
      }
    }
  }
  &__icon, &__value {
    line-height: 1;
  }
  &__icon {
    font-size: 1.4rem;
    color: $c-primary;
    @include respond-to(small) {
      font-size: 1.2rem;
    }
  }
  &__value {
    @include respond-to(small) {
      font-size: 1.5rem !important;
    }
  }
}