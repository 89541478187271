.button {
  position: relative;
  appearance: none;
  display: inline-block;
  font-family: $f-primary;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
  color: $c-primary;
  background: transparent;
  cursor: pointer;
  letter-spacing: 0.2rem;
  line-height: 1;
  border: none;
  text-decoration: none;
  padding: 1.6rem 2rem;
  min-width: 16rem;
  text-align: center;

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 1px rgba($c-secondary, .2);
  }

  &:hover &,
  &:focus & {
    border: none;
    &__line-1 {
      animation: move1 1500ms infinite ease;
    }
    &__line-2 {
      animation: move2 1500ms infinite ease;
    }
    &__line-3 {
      animation: move3 1500ms infinite ease;
    }
    &__line-4 {
      animation: move4 1500ms infinite ease;
    }
  }
  
  &__line-1, &__line-2, &__line-3, &__line-4 {
    content: "";
    display: block;
    position: absolute;
    background-color: $c-primary;
    @extend %anim1;
  }
  &__line-1 {
    width: 1px;
    height: 100%;
    left: 0;
    bottom: 0;
  }
  &__line-2 {
    height: 1px;
    width: 100%;
    left: 0;
    top: 0;
  }
  &__line-3 {
    width: 1px;
    height: 100%;
    right: 0;
    top: 0;
  }
  &__line-4 {
    height: 1px;
    width: 100%;
    right: 0;
    bottom: 0;
  }
}

@keyframes move1 {
  0% {
    height: 100%; 
    bottom: 0;
  }
  54% {
    height: 0; 
    bottom: 100%;
  }
  55% {
    height: 0; 
    bottom: 0;
  }
  100% {
    height: 100%; 
    bottom: 0;
  }
}

@keyframes move2 {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 100%; 
    left: 0;
  }
  100% {
    width: 0; 
    left: 100%;
  }
}

@keyframes move3 {
  0% {
    height: 100%; 
    top: 0;
  }
  54% {
    height: 0; 
    top: 100%;
  }
  55% {
    height: 0; 
    top: 0;
  }
  100% {
    height: 100%; 
    top: 0;
  }
}

@keyframes move4 {
  0% {
    width: 0; 
    right: 0;
  }
  55% {
    width: 100%; 
    right: 0;
  }
  100% {
    width: 0; 
    right: 100%;
  }
}
