.press-tile {
  &__img {
    max-width: 100%;
  }
  &__cta {
    text-decoration: none;
    display: block;
    @extend %anim2;
    &:hover,
    &:focus {
      opacity: .7;
    }
  }
  &__title {
    margin-top: 2rem;
    line-height: 1.2;
  }
  &__date {
    margin-top: .8rem;
  }
}