.content-block {
  @include respond-to(small) {
    text-align: center;
  }
  &__body {
    display: flex;
    height: 100%;
    flex-direction: column;
    &--space-between {
      justify-content: space-between;
    }
    &--flex-end {
      justify-content: flex-end;
    }
  }
  &__body & {
    &__title {
      margin-top: 4rem;
      @include respond-to(small) {
        margin-top: 0;
      }
    }
  }
  &__title + &__content {
    margin-top: 3.5rem;
    @include respond-to(small) {
      margin-top: 2rem;
    }
  }
  &__title + &__subtitle {
    margin-top: 2.8rem;
    @include respond-to(small) {
      margin-top: 1.5rem;
    }
  }
  &__subtitle {
    margin-bottom: 1rem;
  }
  &__content {
    max-width: 37rem;
    @include respond-to(small) {
      margin: auto;
    }
    &--wide {
      max-width: 44rem;
    }
    &--max {
      max-width: 100%;
    }
  }
  &__meta {
    margin-top: 1.5rem;
  }
  &__cta {
    margin-top: 4.7rem;
    @include respond-to(small) {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }
  }
  &__img {
    max-width: 100%;
    &-wrapper {
      display: flex;
      height: 100%;
      &--bottom {
        align-items: flex-end;
      }
    }
  }
  &__square-img {
    width: 100%;
    position: relative;
    top: 5rem;
    @include respond-to(small) {
      position: static;
      top: auto;
    }
  }
  &__form {
    margin-top: 5rem;
    &__input {
      margin-bottom: 5rem;
    }
  }
}