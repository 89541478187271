.input-field {
  appearance: none;
  border: none;
  border-bottom: 1px $c-grey-3 solid;
  padding: 1rem 0;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: .05rem;
  color: $c-primary;
  font-family: $f-primary;
  font-weight: 300;
  @extend %anim2;
  &:focus {
    border-bottom-color: $c-secondary;
  }
  &--block {
    width: 100%;
    display: block;
  }
}