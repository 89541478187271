.contact {
  &__address {
    font-size: 1.8rem;
    color: $c-primary;
    letter-spacing: .1rem;
    line-height: 1.8;
    text-transform: uppercase;
  }
  &__phone {
    margin-top: 3rem;
  }
  &__mail {
    margin-top: 3rem;
  }
}