$modal-backdrop-z-index: 1002;
$modal-close-z-index: 1004;
$modal-content-wrapper-z-index: 1001;
$modal-content-z-index: 1003;

.modal {
  display: none;
  &.js-modal--open {
    display: block;
  }
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(white, .8);
    z-index: $modal-backdrop-z-index;
  }
  &__content {
    position: relative; 
    z-index: $modal-content-z-index;
    background: white;
    width: 120rem;
    max-width: calc(100% - 3.5rem);
    padding: 5rem;
    max-height: calc(100% - 3.5rem);
    overflow: auto;
    box-shadow: 0 0 35px rgba(0, 0, 0, .1);
    &--high {
      height: 90vh;
    }
    &--no-padding {
      padding: 0;
    }
    @include respond-to(small) {
      padding: 3rem;
    }
    @include respond-to(xsmall) {
      padding: 4rem 2rem;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $modal-content-wrapper-z-index;
    }
    &__close {
      position: absolute;
      z-index: $modal-close-z-index;
      top: 1rem;
      right: 1rem;
      border: none;
      background: none;
      font-size: 1.4rem;
      padding: 1rem;
      cursor: pointer;
      color: $c-primary;
      @extend %anim1;
      &:hover, &:focus {
        color: black;
      }
    }
    &__header {
      &__title {
        display: block;
        text-align: center;
      } 
    }
    &__body {
      display: block;
      margin: auto;
      &--small {
        max-width: 30rem;
      }
    }
    &__footer {
      margin-top: 3rem;
      text-align: center;
    }
  }
}