/* ---------------------------------------------------------------------------- Fonts ------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- General variables ------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- Colors ------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- Spacing ------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- Mixins ------------------------------------------------------------------------- */
/**
*
* General items
*
**/
.u-clearfix:after, .layout:after, .region:after, .clearfix:after { content: ""; display: table; clear: both; }

/**
*
* Responsive queries
*
**/
#hidden, .hidden { display: none !important; }

/* ---------------------------------------------------------------------------- Structure & defaults ------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- Structure -- Define structural reset of certain items ------------------------------------------------------------------------- */
* { margin: 0; padding: 0; outline: 0; }

* { box-sizing: border-box; font-smoothing: antialiased; text-rendering: optimizeLegibility; }

*:before, *:after { box-sizing: inherit; font-smoothing: antialiased; text-rendering: optimizeLegibility; }

html { font-size: 62.5%; min-height: 100%; }

body { position: relative; background: #FFFFFF; font-family: "Montserrat", sans-serif; font-weight: 400; color: #000000; min-height: 100%; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

/* ---------------------------------------------------------------------------- Utilities ------------------------------------------------------------------------- */
.u-visually-hidden { position: absolute !important; height: 1px; width: 1px; overflow: hidden; clip: rect(1px, 1px, 1px, 1px); margin: -1px; padding: 0; border: 0; left: 0; top: 0; }

.u-align-left { text-align: left !important; }

.u-align-center { text-align: center !important; }

.u-align-right { text-align: right !important; }

@media screen and (min-width: 1023.1px) { .u-align-left--l { text-align: left !important; }
  .u-align-center--l { text-align: center !important; }
  .u-align-right--l { text-align: right !important; } }

@media screen and (max-width: 1023px) { .u-align-left--m { text-align: left !important; }
  .u-align-center--m { text-align: center !important; }
  .u-align-right--m { text-align: right !important; } }

@media screen and (max-width: 767px) { .u-align-left--s { text-align: left !important; }
  .u-align-center--s { text-align: center !important; }
  .u-align-right--s { text-align: right !important; } }

@media screen and (max-width: 500px) { .u-align-left--xs { text-align: left !important; }
  .u-align-center--xs { text-align: center !important; }
  .u-align-right--xs { text-align: right !important; } }

.button__line-1, .button__line-2, .button__line-3, .button__line-4, .modal__content__close { transition: all 0.1s ease-in-out; }

.link:before, .input-field, .navigation__cta:before, .press-tile__cta, .prev-next-buttons__previous, .prev-next-buttons__next { transition: all 0.2s ease-in-out; }

@keyframes bounce { 0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-12px); }
  60% { transform: translateY(-5px); }
  90% { transform: translateY(-2px); } }

/* .u-divider { border: none; border-bottom: 1px $c-border solid; width: 100%; } .u-divider--margin-top { margin-top: 3rem; } .u-divider--accent { max-width: 12rem; border-bottom: 3px $c-secondary solid; } */
.u-position-absolute { position: absolute !important; }

.u-position-relative { position: relative !important; }

.u-float-right { float: right !important; }

.u-float-left { float: left !important; }

.u-float-none { float: none !important; }

.u-display-inline-block { display: inline-block !important; }

.u-no-overflow { overflow: hidden; }

.u-named-anchor:before { content: ''; display: block; height: 90px; margin: -90px 0 0; z-index: -1; position: relative; }

@media screen and (max-width: 767px) { .u-hidden-mobile { display: none !important; } }

.u-visible-mobile { display: none; }

@media screen and (max-width: 767px) { .u-visible-mobile { display: block; } }

.u-spacer--small { margin-bottom: 2rem; }

.u-spacer--tiny { margin-bottom: 1rem; }

.u-spacer { margin-bottom: 3rem; }

.u-spacer--large { margin-bottom: 6rem; }

@media screen and (max-width: 767px) { .u-spacer--large { margin-bottom: 4rem; } }

.u-spacer--none { margin-bottom: 0; }

.u-space--horizontal + .u-space--horizontal { margin-left: 3rem; }

@media screen and (max-width: 767px) { .u-mobile-spacer { margin-bottom: 2rem; } }

::selection { background: #474a4f; color: white; }

::-moz-selection { background: #474a4f; color: white; }

@font-face { font-family: "mmb_iconfont"; src: url("/fonts/iconfont/mmb_iconfont.eot"); src: url("/fonts/iconfont/mmb_iconfont.eot?#iefix") format("eot"), url("/fonts/iconfont/mmb_iconfont.woff") format("woff"), url("/fonts/iconfont/mmb_iconfont.ttf") format("truetype"), url("/fonts/iconfont/mmb_iconfont.svg#mmb_iconfont") format("svg"); }

.mmbi:before { font-family: "mmb_iconfont"; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; font-style: normal; font-variant: normal; font-weight: normal; /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */ text-decoration: none; text-transform: none; }

.mmbi-arrow-left:before { content: "\E001"; }

.mmbi-arrow-right:before { content: "\E002"; }

.mmbi-close:before { content: "\E003"; }

.u-typography p { display: block; font-family: "droid-serif", serif; font-size: 1.3rem; font-weight: 400; color: #474A4F; line-height: 1.6; font-style: italic; }

.u-typography strong { font-weight: 500; }

.u-typography a { font-weight: 500; color: #474A4F; text-decoration-skip: ink; }

.u-typography > *:not(:last-child) { margin-bottom: 2rem; }

@media screen and (max-width: 767px) { .u-typography > *:not(:last-child) { margin-bottom: 1rem; } }

.u-typography ul li { margin-left: 1.8rem; line-height: 1.8; font-size: 1.3rem; }

.u-typography blockquote p { text-transform: uppercase; font-weight: 400; letter-spacing: 0.05rem; color: #474A4F; font-size: 1.3rem; font-weight: 300; font-style: normal; font-family: "Montserrat", sans-serif; }

@media screen and (max-width: 500px) { .u-typography blockquote p { font-size: 1.2rem; } }

.u-typography blockquote br { display: none; }

.u-typography blockquote footer { margin-top: 1rem; }

.u-typography blockquote footer:before { content: ''; display: inline-block; vertical-align: middle; width: 2.1rem; height: .1rem; background: rgba(136, 136, 136, 0.4); margin-right: 1rem; }

.u-typography blockquote footer span { display: inline-block; vertical-align: middle; font-size: 1.1rem; font-family: "droid-serif", serif; color: #474A4F; font-style: italic; letter-spacing: .05rem; }

.u-text-tertiary { text-transform: uppercase; font-weight: 400; letter-spacing: 0.05rem; color: #888888; font-size: 1.1rem; font-weight: 300; }

.u-underline--alt { position: relative; }

.u-underline--alt:before { content: ''; position: absolute; left: 0; right: 0; bottom: -.4rem; width: 100%; height: .3rem; background: #ABA3BC; }

.button { position: relative; appearance: none; display: inline-block; font-family: "Montserrat", sans-serif; font-size: 1.2rem; font-weight: 400; text-transform: uppercase; color: #474A4F; background: transparent; cursor: pointer; letter-spacing: 0.2rem; line-height: 1; border: none; text-decoration: none; padding: 1.6rem 2rem; min-width: 16rem; text-align: center; }

.button:hover, .button:focus { box-shadow: inset 0 0 0 1px rgba(171, 163, 188, 0.2); }

.button:hover .button, .button:focus .button { border: none; }

.button:hover .button__line-1, .button:focus .button__line-1 { animation: move1 1500ms infinite ease; }

.button:hover .button__line-2, .button:focus .button__line-2 { animation: move2 1500ms infinite ease; }

.button:hover .button__line-3, .button:focus .button__line-3 { animation: move3 1500ms infinite ease; }

.button:hover .button__line-4, .button:focus .button__line-4 { animation: move4 1500ms infinite ease; }

.button__line-1, .button__line-2, .button__line-3, .button__line-4 { content: ""; display: block; position: absolute; background-color: #474A4F; }

.button__line-1 { width: 1px; height: 100%; left: 0; bottom: 0; }

.button__line-2 { height: 1px; width: 100%; left: 0; top: 0; }

.button__line-3 { width: 1px; height: 100%; right: 0; top: 0; }

.button__line-4 { height: 1px; width: 100%; right: 0; bottom: 0; }

@keyframes move1 { 0% { height: 100%;
    bottom: 0; }
  54% { height: 0;
    bottom: 100%; }
  55% { height: 0;
    bottom: 0; }
  100% { height: 100%;
    bottom: 0; } }

@keyframes move2 { 0% { width: 0;
    left: 0; }
  50% { width: 100%;
    left: 0; }
  100% { width: 0;
    left: 100%; } }

@keyframes move3 { 0% { height: 100%;
    top: 0; }
  54% { height: 0;
    top: 100%; }
  55% { height: 0;
    top: 0; }
  100% { height: 100%;
    top: 0; } }

@keyframes move4 { 0% { width: 0;
    right: 0; }
  55% { width: 100%;
    right: 0; }
  100% { width: 0;
    right: 100%; } }

.link { position: relative; display: inline-block; font-size: 1.4rem; font-weight: 500; text-decoration: none; }

.link:before { position: absolute; background: #ABA3BC; content: ''; width: 100%; height: 0.2rem; border-radius: 0.2rem; bottom: -0.4rem; }

.link:hover:before, .link:focus:before { width: 110%; }

.link--primary { color: #474A4F; }

.link--secondary { color: #ABA3BC; }

.link--white { color: #FFFFFF; }

/* ---------------------------------------------------------------------------- Vendor ------------------------------------------------------------------------- */
@font-face { font-family: 'themify'; src: url("/themes/custom/dwb/fonts/themify.eot"); src: url("/themes/custom/dwb/fonts/themify.eot?#iefix") format("embedded-opentype"), url("/themes/custom/dwb/fonts/themify.woff") format("woff"), url("/themes/custom/dwb/fonts/themify.ttf") format("truetype"), url("/themes/custom/dwb/fonts/themify.svg") format("svg"); font-weight: normal; font-style: normal; }

[class^="ti-"]:before, [class*=" ti-"]:before { font-family: 'themify'; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.ti-wand:before { content: "\e600"; }

.ti-volume:before { content: "\e601"; }

.ti-user:before { content: "\e602"; }

.ti-unlock:before { content: "\e603"; }

.ti-unlink:before { content: "\e604"; }

.ti-trash:before { content: "\e605"; }

.ti-thought:before { content: "\e606"; }

.ti-target:before { content: "\e607"; }

.ti-tag:before { content: "\e608"; }

.ti-tablet:before { content: "\e609"; }

.ti-star:before { content: "\e60a"; }

.ti-spray:before { content: "\e60b"; }

.ti-signal:before { content: "\e60c"; }

.ti-shopping-cart:before { content: "\e60d"; }

.ti-shopping-cart-full:before { content: "\e60e"; }

.ti-settings:before { content: "\e60f"; }

.ti-search:before { content: "\e610"; }

.ti-zoom-in:before { content: "\e611"; }

.ti-zoom-out:before { content: "\e612"; }

.ti-cut:before { content: "\e613"; }

.ti-ruler:before { content: "\e614"; }

.ti-ruler-pencil:before { content: "\e615"; }

.ti-ruler-alt:before { content: "\e616"; }

.ti-bookmark:before { content: "\e617"; }

.ti-bookmark-alt:before { content: "\e618"; }

.ti-reload:before { content: "\e619"; }

.ti-plus:before { content: "\e61a"; }

.ti-pin:before { content: "\e61b"; }

.ti-pencil:before { content: "\e61c"; }

.ti-pencil-alt:before { content: "\e61d"; }

.ti-paint-roller:before { content: "\e61e"; }

.ti-paint-bucket:before { content: "\e61f"; }

.ti-na:before { content: "\e620"; }

.ti-mobile:before { content: "\e621"; }

.ti-minus:before { content: "\e622"; }

.ti-medall:before { content: "\e623"; }

.ti-medall-alt:before { content: "\e624"; }

.ti-marker:before { content: "\e625"; }

.ti-marker-alt:before { content: "\e626"; }

.ti-arrow-up:before { content: "\e627"; }

.ti-arrow-right:before { content: "\e628"; }

.ti-arrow-left:before { content: "\e629"; }

.ti-arrow-down:before { content: "\e62a"; }

.ti-lock:before { content: "\e62b"; }

.ti-location-arrow:before { content: "\e62c"; }

.ti-link:before { content: "\e62d"; }

.ti-layout:before { content: "\e62e"; }

.ti-layers:before { content: "\e62f"; }

.ti-layers-alt:before { content: "\e630"; }

.ti-key:before { content: "\e631"; }

.ti-import:before { content: "\e632"; }

.ti-image:before { content: "\e633"; }

.ti-heart:before { content: "\e634"; }

.ti-heart-broken:before { content: "\e635"; }

.ti-hand-stop:before { content: "\e636"; }

.ti-hand-open:before { content: "\e637"; }

.ti-hand-drag:before { content: "\e638"; }

.ti-folder:before { content: "\e639"; }

.ti-flag:before { content: "\e63a"; }

.ti-flag-alt:before { content: "\e63b"; }

.ti-flag-alt-2:before { content: "\e63c"; }

.ti-eye:before { content: "\e63d"; }

.ti-export:before { content: "\e63e"; }

.ti-exchange-vertical:before { content: "\e63f"; }

.ti-desktop:before { content: "\e640"; }

.ti-cup:before { content: "\e641"; }

.ti-crown:before { content: "\e642"; }

.ti-comments:before { content: "\e643"; }

.ti-comment:before { content: "\e644"; }

.ti-comment-alt:before { content: "\e645"; }

.ti-close:before { content: "\e646"; }

.ti-clip:before { content: "\e647"; }

.ti-angle-up:before { content: "\e648"; }

.ti-angle-right:before { content: "\e649"; }

.ti-angle-left:before { content: "\e64a"; }

.ti-angle-down:before { content: "\e64b"; }

.ti-check:before { content: "\e64c"; }

.ti-check-box:before { content: "\e64d"; }

.ti-camera:before { content: "\e64e"; }

.ti-announcement:before { content: "\e64f"; }

.ti-brush:before { content: "\e650"; }

.ti-briefcase:before { content: "\e651"; }

.ti-bolt:before { content: "\e652"; }

.ti-bolt-alt:before { content: "\e653"; }

.ti-blackboard:before { content: "\e654"; }

.ti-bag:before { content: "\e655"; }

.ti-move:before { content: "\e656"; }

.ti-arrows-vertical:before { content: "\e657"; }

.ti-arrows-horizontal:before { content: "\e658"; }

.ti-fullscreen:before { content: "\e659"; }

.ti-arrow-top-right:before { content: "\e65a"; }

.ti-arrow-top-left:before { content: "\e65b"; }

.ti-arrow-circle-up:before { content: "\e65c"; }

.ti-arrow-circle-right:before { content: "\e65d"; }

.ti-arrow-circle-left:before { content: "\e65e"; }

.ti-arrow-circle-down:before { content: "\e65f"; }

.ti-angle-double-up:before { content: "\e660"; }

.ti-angle-double-right:before { content: "\e661"; }

.ti-angle-double-left:before { content: "\e662"; }

.ti-angle-double-down:before { content: "\e663"; }

.ti-zip:before { content: "\e664"; }

.ti-world:before { content: "\e665"; }

.ti-wheelchair:before { content: "\e666"; }

.ti-view-list:before { content: "\e667"; }

.ti-view-list-alt:before { content: "\e668"; }

.ti-view-grid:before { content: "\e669"; }

.ti-uppercase:before { content: "\e66a"; }

.ti-upload:before { content: "\e66b"; }

.ti-underline:before { content: "\e66c"; }

.ti-truck:before { content: "\e66d"; }

.ti-timer:before { content: "\e66e"; }

.ti-ticket:before { content: "\e66f"; }

.ti-thumb-up:before { content: "\e670"; }

.ti-thumb-down:before { content: "\e671"; }

.ti-text:before { content: "\e672"; }

.ti-stats-up:before { content: "\e673"; }

.ti-stats-down:before { content: "\e674"; }

.ti-split-v:before { content: "\e675"; }

.ti-split-h:before { content: "\e676"; }

.ti-smallcap:before { content: "\e677"; }

.ti-shine:before { content: "\e678"; }

.ti-shift-right:before { content: "\e679"; }

.ti-shift-left:before { content: "\e67a"; }

.ti-shield:before { content: "\e67b"; }

.ti-notepad:before { content: "\e67c"; }

.ti-server:before { content: "\e67d"; }

.ti-quote-right:before { content: "\e67e"; }

.ti-quote-left:before { content: "\e67f"; }

.ti-pulse:before { content: "\e680"; }

.ti-printer:before { content: "\e681"; }

.ti-power-off:before { content: "\e682"; }

.ti-plug:before { content: "\e683"; }

.ti-pie-chart:before { content: "\e684"; }

.ti-paragraph:before { content: "\e685"; }

.ti-panel:before { content: "\e686"; }

.ti-package:before { content: "\e687"; }

.ti-music:before { content: "\e688"; }

.ti-music-alt:before { content: "\e689"; }

.ti-mouse:before { content: "\e68a"; }

.ti-mouse-alt:before { content: "\e68b"; }

.ti-money:before { content: "\e68c"; }

.ti-microphone:before { content: "\e68d"; }

.ti-menu:before { content: "\e68e"; }

.ti-menu-alt:before { content: "\e68f"; }

.ti-map:before { content: "\e690"; }

.ti-map-alt:before { content: "\e691"; }

.ti-loop:before { content: "\e692"; }

.ti-location-pin:before { content: "\e693"; }

.ti-list:before { content: "\e694"; }

.ti-light-bulb:before { content: "\e695"; }

.ti-Italic:before { content: "\e696"; }

.ti-info:before { content: "\e697"; }

.ti-infinite:before { content: "\e698"; }

.ti-id-badge:before { content: "\e699"; }

.ti-hummer:before { content: "\e69a"; }

.ti-home:before { content: "\e69b"; }

.ti-help:before { content: "\e69c"; }

.ti-headphone:before { content: "\e69d"; }

.ti-harddrives:before { content: "\e69e"; }

.ti-harddrive:before { content: "\e69f"; }

.ti-gift:before { content: "\e6a0"; }

.ti-game:before { content: "\e6a1"; }

.ti-filter:before { content: "\e6a2"; }

.ti-files:before { content: "\e6a3"; }

.ti-file:before { content: "\e6a4"; }

.ti-eraser:before { content: "\e6a5"; }

.ti-envelope:before { content: "\e6a6"; }

.ti-download:before { content: "\e6a7"; }

.ti-direction:before { content: "\e6a8"; }

.ti-direction-alt:before { content: "\e6a9"; }

.ti-dashboard:before { content: "\e6aa"; }

.ti-control-stop:before { content: "\e6ab"; }

.ti-control-shuffle:before { content: "\e6ac"; }

.ti-control-play:before { content: "\e6ad"; }

.ti-control-pause:before { content: "\e6ae"; }

.ti-control-forward:before { content: "\e6af"; }

.ti-control-backward:before { content: "\e6b0"; }

.ti-cloud:before { content: "\e6b1"; }

.ti-cloud-up:before { content: "\e6b2"; }

.ti-cloud-down:before { content: "\e6b3"; }

.ti-clipboard:before { content: "\e6b4"; }

.ti-car:before { content: "\e6b5"; }

.ti-calendar:before { content: "\e6b6"; }

.ti-book:before { content: "\e6b7"; }

.ti-bell:before { content: "\e6b8"; }

.ti-basketball:before { content: "\e6b9"; }

.ti-bar-chart:before { content: "\e6ba"; }

.ti-bar-chart-alt:before { content: "\e6bb"; }

.ti-back-right:before { content: "\e6bc"; }

.ti-back-left:before { content: "\e6bd"; }

.ti-arrows-corner:before { content: "\e6be"; }

.ti-archive:before { content: "\e6bf"; }

.ti-anchor:before { content: "\e6c0"; }

.ti-align-right:before { content: "\e6c1"; }

.ti-align-left:before { content: "\e6c2"; }

.ti-align-justify:before { content: "\e6c3"; }

.ti-align-center:before { content: "\e6c4"; }

.ti-alert:before { content: "\e6c5"; }

.ti-alarm-clock:before { content: "\e6c6"; }

.ti-agenda:before { content: "\e6c7"; }

.ti-write:before { content: "\e6c8"; }

.ti-window:before { content: "\e6c9"; }

.ti-widgetized:before { content: "\e6ca"; }

.ti-widget:before { content: "\e6cb"; }

.ti-widget-alt:before { content: "\e6cc"; }

.ti-wallet:before { content: "\e6cd"; }

.ti-video-clapper:before { content: "\e6ce"; }

.ti-video-camera:before { content: "\e6cf"; }

.ti-vector:before { content: "\e6d0"; }

.ti-themify-logo:before { content: "\e6d1"; }

.ti-themify-favicon:before { content: "\e6d2"; }

.ti-themify-favicon-alt:before { content: "\e6d3"; }

.ti-support:before { content: "\e6d4"; }

.ti-stamp:before { content: "\e6d5"; }

.ti-split-v-alt:before { content: "\e6d6"; }

.ti-slice:before { content: "\e6d7"; }

.ti-shortcode:before { content: "\e6d8"; }

.ti-shift-right-alt:before { content: "\e6d9"; }

.ti-shift-left-alt:before { content: "\e6da"; }

.ti-ruler-alt-2:before { content: "\e6db"; }

.ti-receipt:before { content: "\e6dc"; }

.ti-pin2:before { content: "\e6dd"; }

.ti-pin-alt:before { content: "\e6de"; }

.ti-pencil-alt2:before { content: "\e6df"; }

.ti-palette:before { content: "\e6e0"; }

.ti-more:before { content: "\e6e1"; }

.ti-more-alt:before { content: "\e6e2"; }

.ti-microphone-alt:before { content: "\e6e3"; }

.ti-magnet:before { content: "\e6e4"; }

.ti-line-double:before { content: "\e6e5"; }

.ti-line-dotted:before { content: "\e6e6"; }

.ti-line-dashed:before { content: "\e6e7"; }

.ti-layout-width-full:before { content: "\e6e8"; }

.ti-layout-width-default:before { content: "\e6e9"; }

.ti-layout-width-default-alt:before { content: "\e6ea"; }

.ti-layout-tab:before { content: "\e6eb"; }

.ti-layout-tab-window:before { content: "\e6ec"; }

.ti-layout-tab-v:before { content: "\e6ed"; }

.ti-layout-tab-min:before { content: "\e6ee"; }

.ti-layout-slider:before { content: "\e6ef"; }

.ti-layout-slider-alt:before { content: "\e6f0"; }

.ti-layout-sidebar-right:before { content: "\e6f1"; }

.ti-layout-sidebar-none:before { content: "\e6f2"; }

.ti-layout-sidebar-left:before { content: "\e6f3"; }

.ti-layout-placeholder:before { content: "\e6f4"; }

.ti-layout-menu:before { content: "\e6f5"; }

.ti-layout-menu-v:before { content: "\e6f6"; }

.ti-layout-menu-separated:before { content: "\e6f7"; }

.ti-layout-menu-full:before { content: "\e6f8"; }

.ti-layout-media-right-alt:before { content: "\e6f9"; }

.ti-layout-media-right:before { content: "\e6fa"; }

.ti-layout-media-overlay:before { content: "\e6fb"; }

.ti-layout-media-overlay-alt:before { content: "\e6fc"; }

.ti-layout-media-overlay-alt-2:before { content: "\e6fd"; }

.ti-layout-media-left-alt:before { content: "\e6fe"; }

.ti-layout-media-left:before { content: "\e6ff"; }

.ti-layout-media-center-alt:before { content: "\e700"; }

.ti-layout-media-center:before { content: "\e701"; }

.ti-layout-list-thumb:before { content: "\e702"; }

.ti-layout-list-thumb-alt:before { content: "\e703"; }

.ti-layout-list-post:before { content: "\e704"; }

.ti-layout-list-large-image:before { content: "\e705"; }

.ti-layout-line-solid:before { content: "\e706"; }

.ti-layout-grid4:before { content: "\e707"; }

.ti-layout-grid3:before { content: "\e708"; }

.ti-layout-grid2:before { content: "\e709"; }

.ti-layout-grid2-thumb:before { content: "\e70a"; }

.ti-layout-cta-right:before { content: "\e70b"; }

.ti-layout-cta-left:before { content: "\e70c"; }

.ti-layout-cta-center:before { content: "\e70d"; }

.ti-layout-cta-btn-right:before { content: "\e70e"; }

.ti-layout-cta-btn-left:before { content: "\e70f"; }

.ti-layout-column4:before { content: "\e710"; }

.ti-layout-column3:before { content: "\e711"; }

.ti-layout-column2:before { content: "\e712"; }

.ti-layout-accordion-separated:before { content: "\e713"; }

.ti-layout-accordion-merged:before { content: "\e714"; }

.ti-layout-accordion-list:before { content: "\e715"; }

.ti-ink-pen:before { content: "\e716"; }

.ti-info-alt:before { content: "\e717"; }

.ti-help-alt:before { content: "\e718"; }

.ti-headphone-alt:before { content: "\e719"; }

.ti-hand-point-up:before { content: "\e71a"; }

.ti-hand-point-right:before { content: "\e71b"; }

.ti-hand-point-left:before { content: "\e71c"; }

.ti-hand-point-down:before { content: "\e71d"; }

.ti-gallery:before { content: "\e71e"; }

.ti-face-smile:before { content: "\e71f"; }

.ti-face-sad:before { content: "\e720"; }

.ti-credit-card:before { content: "\e721"; }

.ti-control-skip-forward:before { content: "\e722"; }

.ti-control-skip-backward:before { content: "\e723"; }

.ti-control-record:before { content: "\e724"; }

.ti-control-eject:before { content: "\e725"; }

.ti-comments-smiley:before { content: "\e726"; }

.ti-brush-alt:before { content: "\e727"; }

.ti-youtube:before { content: "\e728"; }

.ti-vimeo:before { content: "\e729"; }

.ti-twitter:before { content: "\e72a"; }

.ti-time:before { content: "\e72b"; }

.ti-tumblr:before { content: "\e72c"; }

.ti-skype:before { content: "\e72d"; }

.ti-share:before { content: "\e72e"; }

.ti-share-alt:before { content: "\e72f"; }

.ti-rocket:before { content: "\e730"; }

.ti-pinterest:before { content: "\e731"; }

.ti-new-window:before { content: "\e732"; }

.ti-microsoft:before { content: "\e733"; }

.ti-list-ol:before { content: "\e734"; }

.ti-linkedin:before { content: "\e735"; }

.ti-layout-sidebar-2:before { content: "\e736"; }

.ti-layout-grid4-alt:before { content: "\e737"; }

.ti-layout-grid3-alt:before { content: "\e738"; }

.ti-layout-grid2-alt:before { content: "\e739"; }

.ti-layout-column4-alt:before { content: "\e73a"; }

.ti-layout-column3-alt:before { content: "\e73b"; }

.ti-layout-column2-alt:before { content: "\e73c"; }

.ti-instagram:before { content: "\e73d"; }

.ti-google:before { content: "\e73e"; }

.ti-github:before { content: "\e73f"; }

.ti-flickr:before { content: "\e740"; }

.ti-facebook:before { content: "\e741"; }

.ti-dropbox:before { content: "\e742"; }

.ti-dribbble:before { content: "\e743"; }

.ti-apple:before { content: "\e744"; }

.ti-android:before { content: "\e745"; }

.ti-save:before { content: "\e746"; }

.ti-save-alt:before { content: "\e747"; }

.ti-yahoo:before { content: "\e748"; }

.ti-wordpress:before { content: "\e749"; }

.ti-vimeo-alt:before { content: "\e74a"; }

.ti-twitter-alt:before { content: "\e74b"; }

.ti-tumblr-alt:before { content: "\e74c"; }

.ti-trello:before { content: "\e74d"; }

.ti-stack-overflow:before { content: "\e74e"; }

.ti-soundcloud:before { content: "\e74f"; }

.ti-sharethis:before { content: "\e750"; }

.ti-sharethis-alt:before { content: "\e751"; }

.ti-reddit:before { content: "\e752"; }

.ti-pinterest-alt:before { content: "\e753"; }

.ti-microsoft-alt:before { content: "\e754"; }

.ti-linux:before { content: "\e755"; }

.ti-jsfiddle:before { content: "\e756"; }

.ti-joomla:before { content: "\e757"; }

.ti-html5:before { content: "\e758"; }

.ti-flickr-alt:before { content: "\e759"; }

.ti-email:before { content: "\e75a"; }

.ti-drupal:before { content: "\e75b"; }

.ti-dropbox-alt:before { content: "\e75c"; }

.ti-css3:before { content: "\e75d"; }

.ti-rss:before { content: "\e75e"; }

.ti-rss-alt:before { content: "\e75f"; }

.df-abs-center { top: 50%; left: 50%; position: absolute; -webkit-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); }

.df-abs-h-center { position: absolute; left: 50%; -webkit-transform: translate(-50%); -moz-transform: translate(-50%); -ms-transform: translate(-50%); transform: translate(-50%); }

.df-container { min-height: 320px; background: no-repeat 50%; background-size: cover; width: 100%; position: relative; /* background-color: #A6322D; */ /*Smoothing of images in Firefox*/ image-rendering: auto; direction: ltr; }

.df-container.df-transparent:not(.df-fullscreen) { background: transparent !important; }

.df-container.df-custom-fullscreen.df-fullscreen { position: fixed !important; top: 0 !important; left: 0 !important; right: 0 !important; bottom: 0 !important; margin: 0 !important; border: 0 !important; z-index: 999999; }

.df-container .loading-info { position: absolute; top: 100%; margin-top: 20px; margin-left: 5px; padding: 6px 20px; background-color: rgba(0, 0, 0, 0.09); font-style: italic; border-radius: 5px; font-size: 11px; max-height: 30px; color: #fff; max-width: 200px; transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -webkit-transform: translateX(-50%); left: 50%; opacity: 0.7; transition: 0.3s top ease; -webkit-transition: 0.3s top ease; }

.loading-info.df-old-browser { color: gray; background-color: white; opacity: 0.9; text-align: center; max-height: none; }

.loading-info.df-old-browser a { font-weight: bold; font-style: normal; text-transform: uppercase; color: #2196F3; display: block; text-decoration: underline; margin: 10px 0 0; }

.df-container.df-init .loading-info { top: 50%; }

.df-3dcanvas { position: absolute; z-index: 1; right: 0; }

.df-container a { outline: none; text-decoration: none; box-shadow: none; }

.df-container.df-zoom-enabled canvas.df-3dcanvas, .df-container.df-zoom-enabled .df-book-stage { cursor: move; /*noinspection Annotator*/ cursor: -webkit-grab; }

.df-container:before, .df-container:after { content: " "; transition: 0.3s 0.1s opacity; -webkit-transition: 0.3s 0.1s opacity; opacity: 0; height: 50px; width: 50px; left: 50%; top: 50%; font-size: 12px; margin: -45px -25px -25px; box-sizing: border-box; position: absolute; pointer-events: none; }

.df-container.df-loading:before, .df-container.df-loading:after { content: " "; z-index: 100; opacity: 1; /*display:none;*/ }

.df-container.df-loading:after { content: " "; background-image: url("/img/dflip/loading.gif?"); background-repeat: no-repeat; background-size: 32px; background-position: 50%; }

.df-container.df-loading:before { background-color: white; -webkit-border-radius: 50px; -moz-border-radius: 50px; border-radius: 50px; border: 1px solid rgba(150, 150, 150, 0.2); }

.df-book-stage { position: absolute; overflow: hidden; right: 0; bottom: 0; z-index: 1; top: 0; left: 0; /*Blurry image fixation*/ filter: blur(0); -webkit-filter: blur(0); padding: 30px 50px 70px; }

.df-container.df-xs .df-book-stage { padding-left: 20px; padding-right: 20px; }

.df-container.df-sidemenu-open .df-book-stage { padding-left: 230px; padding-right: 10px; }

.df-container.df-sidemenu-open > .df-ui-btn.df-ui-prev { left: 220px; }

.df-book-stage.df-zoom-enabled { cursor: move; }

.df-page-front:before, .df-page-back:before { content: " "; }

.df-book-page .df-page-front:before { background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0.15) 25%, transparent 70%); background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0.15) 25%, transparent 70%); background-image: -ms-linear-gradient(right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0.15) 25%, transparent 70%); }

.df-book-page .df-page-back:before { background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.15) 15%, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0)); background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.15) 15%, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0)); background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.15) 15%, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0)); }

.df-book-wrapper, .df-page-wrapper, .df-book-page, .df-page-content, .df-page-front:before, .df-page-wrapper:after, .df-page-back:before, .df-page-content:after, .df-page-effects, .df-page-effects:before, .df-page-effects { position: absolute; height: 100%; display: block; top: 0; }

.df-page-content, .df-book-page { width: 100%; }

.df-book-wrapper { position: relative; margin: 0 auto; transition: 0.3s left; -webkit-transition: 0.3s left; left: 0; /* box-shadow: 0 2px 8px $c-primary; */ }

.df-zoom-enabled .df-book-wrapper { transition: none; -webkit-transition: none; }

.df-loading.df-container .df-book-wrapper { left: 100%; }

.df-book-wrapper.close-right { left: 25%; }

.df-book-wrapper.close-left { left: -25%; }

.df-book-shadow { position: absolute; z-index: -1; height: 100%; }

.df-book-wrapper .df-book-side-buttons { position: absolute; left: 100%; width: 40px; opacity: 0.3; height: 100%; cursor: pointer; z-index: 30; -webkit-border-radius: 0 20px 20px 0; -moz-border-radius: 0 20px 20px 0; border-radius: 0 20px 20px 0; }

.df-book-prev-button.df-book-side-buttons { -webkit-border-radius: 20px 0 0 20px; -moz-border-radius: 20px 0 0 20px; border-radius: 20px 0 0 20px; }

.df-book-wrapper .df-book-side-buttons:hover { opacity: 0.5; background-color: rgba(0, 0, 0, 0.18); }

.df-book-wrapper .df-book-prev-button { right: 100%; left: auto; left: initial; }

.df-book-wrapper .df-book-side-buttons:before { position: absolute; font-size: 30px; -webkit-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); left: 50%; top: 50%; }

.df-page-wrapper { overflow: hidden; }

.df-hard-page .df-page-wrapper { width: 100% !important; height: 100% !important; overflow: visible; transform-style: preserve-3d; -webkit-transform-origin: 0 50% 0; -moz-transform-origin: 0 50% 0; -ms-transform-origin: 0 50% 0; transform-origin: 0 50% 0; }

.df-book-page.df-left-side { left: 0; -webkit-transform-origin: right; -moz-transform-origin: right; -ms-transform-origin: right; transform-origin: right; }

.df-book-page.df-right-side { left: 50%; -webkit-transform-origin: left; -moz-transform-origin: left; -ms-transform-origin: left; transform-origin: left; }

.df-book-page.df-right-side + .df-page-wrapper.df-right-side { z-index: -1; }

.df-book-page .df-page-front, .df-book-page .df-page-back { width: 100%; background-size: 100.5% 100%; background-size: 100% 100%; height: 100%; background-color: white; position: absolute; box-sizing: border-box; }

.df-page-back > canvas, .df-page-front > canvas { width: 100%; position: relative; z-index: -1; }

/*right left shadow during flip*/
.df-quick-turn:before { opacity: 0; }

.df-book-page.df-flipping.df-left-side:before { background-image: -webkit-linear-gradient(left, transparent, rgba(0, 0, 0, 0.1)); background-image: -moz-linear-gradient(left, transparent, rgba(0, 0, 0, 0.1)); width: 10px; left: -10px; }

.df-book-page { width: 50%; }

.df-book-page.df-flipping, .df-book-page.df-folding { z-index: 200 !important; }

.df-page-content { background-size: 100.25% !important; background-repeat: no-repeat !important; transition-property: transform; -webkit-transition-property: -webkit-transform; width: 100%; overflow: hidden; /*this causes page cuts in IE*/ }

.df-book-page.df-left-side .df-page-front { /*overflow: hidden;*/ z-index: 2; }

.df-book-page.df-left-side .df-page-back { z-index: 1; }

.df-book-page.df-left-side.df-folding:not(.df-hard-page) .df-page-back { z-index: 3; }

.df-book-page.df-right-side .df-page-front { top: 0; left: 0; z-index: 0; }

.df-book-page, .df-page-front, .df-page-back, .df-page-fold-inner-shadow, .df-page-fold-outer-shadow, .df-page-wrapper { position: absolute; -webkit-transform-origin: 0 0 0; -moz-transform-origin: 0 0 0; -ms-transform-origin: 0 0 0; transform-origin: 0 0 0; }

.df-page-front, .df-page-back { overflow: visible; }

.df-page-fold-outer-shadow { z-index: -1; width: 100%; height: 100%; }

.df-page-fold-inner-shadow { position: absolute; z-index: 5; display: none; }

.df-book-page.df-folding .df-page-fold-inner-shadow { display: block; }

.df-book-page.df-right-side .df-page-back { left: 0; z-index: 1; }

.df-book-page.df-right-side.df-folding .df-page-front { z-index: 2; }

.df-page-wrapper.df-right-side .df-page-back { left: 100%; z-index: 1; overflow: hidden; }

.df-hard-page .df-page-front, .df-hard-page .df-page-back { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; -ms-backface-visibility: hidden; backface-visibility: hidden; }

.df-hard-page.df-right-side .df-page-front { -webkit-transform-origin: right 50%; -moz-transform-origin: right 50%; -ms-transform-origin: right 50%; transform-origin: right 50%; z-index: 3 !important; /*transform-style: preserve-3d;*/ /*this causes issues in FF*/ }

.df-hard-page.df-left-side .df-page-front { -webkit-transform-origin: right 50%; -moz-transform-origin: right 50%; -ms-transform-origin: right 50%; transform-origin: right 50%; }

.df-hard-page.df-right-side .df-page-back { -webkit-transform-origin: left 50%; -moz-transform-origin: left 50%; -ms-transform-origin: left 50%; transform-origin: left 50%; z-index: 3; /*transform-style: preserve-3d;*/ /*this causes issues in FF*/ }

.df-hard-page.df-left-side .df-page-back { -webkit-transform-origin: left 50%; -moz-transform-origin: left 50%; -ms-transform-origin: left 50%; transform-origin: left 50%; }

/*Mid shadows*/
.df-page-front:before, .df-page-back:before { width: 20%; z-index: 5; pointer-events: none; }

.df-page-front:before { right: 0; }

.df-page-wrapper.df-right-side.df-flipping { z-index: 4; }

/*Book UI*/
.df-ui { height: 60px; padding: 20px 16px 0; }

.df-ui-wrapper, .df-ui-btn { color: #474A4F; vertical-align: top; background-color: #fff; box-sizing: border-box; text-align: center; }

.df-ui-wrapper { /* overflow: hidden; */ background-color: #fff; position: absolute; }

.df-share-wrapper { z-index: 2; position: absolute; top: 0; bottom: 0; right: 0; left: 0; background-color: rgba(0, 0, 0, 0.35); display: none; }

.df-share-box { position: absolute; top: 50%; width: 280px; -webkit-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); -ms-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%); left: 50%; background-color: #fff; border-radius: 10px; padding: 10px 15px 5px; text-align: center; }

span.df-share-title { color: #777; margin-bottom: 10px; display: block; }

.df-share-url { /* border: 0; */ background-color: #fff; height: 60px; color: #666; padding: 2px 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; width: 100%; font-size: 12px; box-sizing: border-box; margin-bottom: 0px; }

.df-share-button { display: inline-block; text-align: center; cursor: pointer; -webkit-border-radius: 50%; -moz-border-radius: 50%; border-radius: 50%; padding: 10px; margin: 3px; color: #474A4F; }

.df-share-button:hover { color: #474A4F; }

.df-ui-btn { position: relative; width: 35px; right: 0; height: 40px; font-size: 17px; cursor: pointer; z-index: 2; display: inline-block; padding: 12px 5px; /* -webkit-border-radius: 3px; */ line-height: 1; }

.df-floating .df-ui-controls > .df-ui-btn:first-child { border-radius: 5px 0 0 5px; }

.df-floating .df-ui-controls > .df-ui-btn:last-child { border-radius: 0 5px 5px 0; }

.df-ui-btn:before { box-sizing: border-box; }

.df-ui-thumbnail, .df-ui-share, .df-ui-outline, .df-ui-help, .df-ui-sound { float: left; }

.df-ui-btn:hover { color: black; }

.df-ui-btn.disabled, .df-ui-btn.disabled:hover { color: #ccc; }

.df-ui-page { padding: 8px 5px 2px; font-size: 12px; width: auto; width: 50px; margin: 0 0; /* border-right: 1px solid #ddd; */ border-radius: 0; /* float: left !important; */ background-color: rgba(0, 0, 0, 0.03); }

.df-ui-page input { width: 100%; background-color: transparent; height: 25px; margin: 0; border: 1px solid #8f8f8f; text-align: center; vertical-align: top; color: transparent; font-size: 14px; top: 0; box-sizing: border-box; z-index: -1; display: inline-block; opacity: 0; }

.df-ui-page input:focus { z-index: 2; opacity: 1; color: inherit; }

.df-ui-page input:focus + label { opacity: 0; display: none; }

.df-ui-page label { position: absolute; height: 30px; width: 100%; display: block; top: 0; padding-top: 14px; /* letter-spacing: 0.5px; */ color: #999; font-size: 13px; /* vertical-align: top; */ font-weight: normal; left: 0; cursor: pointer; background-color: transparent; z-index: 1; box-sizing: border-box; /* border: 1px solid rgba(0, 0, 0, 0.11); */ }

.df-ui-share { float: left; }

.df-ui-fullscreen { bottom: 0; }

.df-ui-zoom { top: 24px; height: 60px; right: 24px; width: 30px; display: none; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; }

.df-ui-sidemenu-close { position: absolute; top: 10px; right: -32px; font-size: 14px; width: 32px; height: 35px; cursor: pointer; padding: 10px 0px; background: inherit; border-radius: 0 5px 5px 0; z-index: -1; transition: 0.5s ease; -webkit-transition: 0.5s ease; }

.df-thumb-visible .df-ui-sidemenu-close, .df-outline-visible .df-ui-sidemenu-close { right: -32px; }

.df-ui-controls { bottom: 0; width: 100%; /* border-radius: 5px; */ height: 40px; /* -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); transform: translateX(-50%); left: 50%; */ padding: 0; -webkit-touch-callout: none; /* iOS Safari */ -webkit-user-select: none; /* Chrome/Safari/Opera */ -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* IE/Edge */ user-select: none; box-shadow: 0 0 25px rgba(0, 0, 0, 0.1); /* non-prefixed version, currently not supported by any browser */ z-index: 4; /* bottom: 15px; */ display: block; }

.df-floating .df-ui-controls { bottom: 10px; width: auto; width: intial; /*IE fallback*/ -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); transform: translateX(-50%); left: 50%; margin: 0 auto; display: table; /*IE fallback*/ display: flex; border-radius: 5px; }

.df-ui-wrapper.df-ui-controls .df-ui-btn { /* display: table-cell; */ float: none; }

.df-ui-size { bottom: 24px; height: 60px; right: 24px; }

.df-ui-zoom:before, .df-ui-size:before { height: 0; border-bottom: 1px solid; content: " "; right: 5px; opacity: 0.3; display: block; position: absolute; top: 30px; left: 5px; z-index: 2; }

.df-ui-zoomin { top: 0; }

.df-ui-zoomout { bottom: 0; }

.df-ui-btn.df-ui-zoomout { /* display: none; */ }

.df-zoom-enabled .df-ui-btn.df-ui-zoomout { /* display: block; */ }

.df-zoom-enabled .df-ui-btn.df-ui-zoomin { /* display: none; */ }

.df-ui-next { right: 24px; right: 0; border-radius: 0; /* background-color: rgba(255, 255, 255, 0.5); */ }

.df-container.df-floating > .df-ui-next { right: 8px; }

.df-container.df-floating > .df-ui-prev { left: 2px; }

.df-container > .df-ui-next, .df-container > .df-ui-prev { margin-top: -5px; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); top: 50%; position: absolute; /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.35); */ opacity: 0.3; background-color: transparent; height: auto; height: initial; font-size: 36px; color: #000; width: auto; text-align: center; width: initial; }

.df-container > .df-ui-next:hover, .df-container > .df-ui-prev:hover { opacity: 0.7; }

.df-container.df-floating > .df-ui-next, .df-container.df-floating > .df-ui-prev { border-radius: 50%; width: 40px; }

.df-ui-prev { left: 0; right: auto; border-radius: 0; }

.df-ui-alt { position: relative !important; right: auto; right: initial; left: auto; left: initial; box-shadow: none !important; }

.df-ui-alt.df-ui-next, .df-ui-alt.df-ui-prev { /* display: none !important; */ }

.df-ui-alt.df-ui-next { border-right: 1px solid #ddd; }

.df-ui-btn.df-active { color: black; background-color: #e7e7e7; }

.df-ui-fit { display: none; }

.df-ui-zoomin, .df-ui-zoomout, .df-ui-fullscreen, .df-ui-more { float: right; }

.df-ui-fit { top: 0; }

.df-ui-more .more-container { display: none; position: absolute; bottom: 100%; margin-bottom: 8px !important; right: 10px; background-color: #fff; z-index: 20; border-radius: 5px; /* overflow: hidden; */ box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.2); }

.more-container:before { content: " "; position: absolute; border: solid transparent; border-width: 7px; border-top-color: #eee; height: 0; width: 0; bottom: -14px; /* background-color: white; */ right: 13px; margin-right: -8px; pointer-events: none; }

.more-container .df-ui-btn { width: auto; width: initial; width: 170px; text-align: left; padding: 8px 10px; height: 36px; border-top: 1px solid #e4e4e4; border-radius: 0; }

.more-container .df-ui-btn:hover { background-color: #eee; }

.more-container > .df-ui-btn:first-child { border-radius: 5px; border-top: none; }

.more-container > .df-ui-btn:last-child { border-radius: 0 0 5px 5px; }

.more-container .df-ui-btn:before { position: absolute; top: 11px; font-size: 14px; }

.df-ui-btn span { display: none; }

.more-container .df-ui-btn span { font-size: 12px; padding-left: 20px; vertical-align: middle; display: inline-block; }

.df-ui-more.df-active .more-container { display: block; }

.annotationLayer section, .annotationDiv section, .customLinkAnnotation, .customHtmlAnnotation, .customVideoAnnotation, .linkAnnotation, .highlightAnnotation, .popupAnnotation { position: absolute; z-index: 5; cursor: pointer; pointer-events: all; /* border: none !important; */ }

.annotationDiv .popupWrapper { position: absolute; width: 20em; }

.annotationDiv .popup { position: absolute; z-index: 200; max-width: 20em; background-color: #FFFF99; box-shadow: 0px 2px 5px #333; border-radius: 2px; padding: 0.6em; margin-left: 5px; cursor: pointer; word-wrap: break-word; }

.annotationDiv .popup h1 { font-size: 1em; border-bottom: 1px solid #000000; padding-bottom: 0.2em; }

.annotationDiv .popup p { padding-top: 0.2em; }

.annotationDiv .highlightAnnotation, .annotationDiv .underlineAnnotation, .annotationDiv .squigglyAnnotation, .annotationDiv .strikeoutAnnotation, .annotationDiv .fileAttachmentAnnotation { cursor: pointer; }

.annotationDiv .textAnnotation img { position: absolute; cursor: pointer; }

section.linkAnnotation a { display: block; height: 100%; }

section.linkAnnotation a, a.linkAnnotation, a.customLinkAnnotation, .customHtmlAnnotation, .customVideoAnnotation { background-color: transparent; background-color: yellow; /* border-bottom: 1px solid #2196F3; */ opacity: 0.1; }

a.customLinkAnnotation { /* opacity: 0.15; */ }

section.linkAnnotation a:hover, a.linkAnnotation:hover, .highlightAnnotation:hover, a.customLinkAnnotation:hover { opacity: 0.4; border-color: transparent; background-color: #2196F3; }

.annotationDiv { position: absolute; top: 0; left: 0; bottom: 0; right: 0; overflow: hidden; }

section.linkAnnotation a .annotationLayer > a { display: block; position: absolute; height: 100%; cursor: pointer; box-sizing: border-box; }

.df-page-loading:after, .df-page-loading:before { content: " "; height: 44px; width: 44px; position: absolute; top: 50%; left: 50%; margin: -22px -22px; }

.df-page-loading:before { background-color: white; -webkit-border-radius: 50px; -moz-border-radius: 50px; border-radius: 50px; /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4); */ box-sizing: border-box; }

.df-page-loading:after { background-image: url("/img/dflip/loading.gif"); background-repeat: no-repeat; background-position: center; background-size: 22px; }

.df-thumb-container .df-vrow { height: 124px; left: 0; width: 90%; margin: 10px auto; background-position: 50%; cursor: pointer; background-repeat: no-repeat; right: 0; border-radius: 5px; /* opacity: 0.7; */ }

.df-thumb-container .df-vrow:before { content: ""; background-image: url("/img/dflip/loading.gif"); background-size: 22px; height: 22px; width: 22px; display: block; top: 60px; margin: 0 auto; position: relative; z-index: 0; }

.df-thumb-container .df-vrow:hover { background-color: #bbb; }

.df-floating .df-thumb-container .df-vrow:hover { background-color: #ccc; opacity: 1; }

.df-thumb-container .df-vrow.df-thumb-loaded { background-size: contain; /* opacity: 1; */ }

.df-thumb-container .df-vrow.df-thumb-loaded:before { display: none; }

.df-vrow > div { /* padding: 25%; */ height: 20px; background-color: rgba(170, 170, 170, 0.54); width: 40px; margin: 0 auto; }

.df-thumb-container { /* left: -100%; */ float: left; /* padding: 20px 0 !important; */ width: 220px; height: auto !important; position: absolute !important; top: 0; z-index: 3; bottom: 40px; background-color: rgba(238, 238, 238, 0.9); /* font-size: 30px; */ /* box-shadow: 1px 0 4px rgba(102, 102, 102, 0.38); */ text-align: center; }

.df-outline-container { /* left: 10px; */ float: left; /* max-height: 400px; */ /* display: none; */ /* padding: 0 20px !important; */ width: 220px; text-align: left; height: auto !important; position: absolute !important; top: 0; bottom: 40px; /* box-shadow: 1px 0 4px rgba(102, 102, 102, 0.38); */ z-index: 3; color: #666; font-size: 12px; background-color: rgba(247, 247, 247, 0.9); /* box-sizing: content-box; */ }

.df-outline-container, .df-thumb-container { transition: 0.5s transform ease; -webkit-transition: 0.5s -webkit-transform ease; transform: translateX(-100%); -webkit-transform: translateX(-100%); -moz-transform: translateX(-100%); -ms-transform: translateX(-100%); left: -50px; }

.df-outline-wrapper, .df-thumb-wrapper { position: absolute !important; top: 0; bottom: 0px; left: 0; right: 0; height: auto !important; }

.df-outline-wrapper { overflow: auto; padding: 10px 10px 5px 10px; }

.df-floating .df-outline-container, .df-floating .df-thumb-container { bottom: 0; }

.df-outline-container.df-outline-visible { display: block; }

.df-outline-container.df-outline-visible, .df-thumb-container.df-thumb-visible { transform: translateX(0); -ms-transform: translateX(0); -moz-transform: translateX(0); -webkit-transform: translateX(0); left: 0; }

.df-floating .df-thumb-container.df-thumb-visible { /* background-color: transparent; */ }

.df-outline-toggle { cursor: pointer; float: left; width: 18px; padding: 5px 0; margin-left: -18px; text-align: center; }

.df-outline-toggle.df-outlines-hidden ~ .df-outline-items { display: none; }

.df-outline-items > .df-outline-item { margin-left: 18px; /* padding: 5px 0; */ }

.df-outline-item a:hover, .df-outline-toggle:hover { background-color: #e7e7e7; }

.df-outline-item a { color: inherit !important; text-decoration: none !important; display: block; padding: 5px 0 5px 5px; overflow: hidden; text-overflow: ellipsis; /* white-space: nowrap; */ border: none !important; box-shadow: none !important; }

.df-outline-toggle:before { content: "\e64b"; font-size: 10px; font-family: 'themify'; }

.df-outline-toggle.df-outlines-hidden:before { content: "\e649"; }

.df-lightbox-wrapper { position: fixed !important; top: 0; bottom: 0; right: 0; height: auto !important; left: 0; background-color: white; z-index: 99999; }

.df-lightbox-controls { /* height: 40px; */ float: right; padding: 5px; z-index: 1000; position: relative; }

.df-lightbox-close { float: right; height: 40px; text-align: center; font-size: 50px; cursor: pointer; /* line-height: 30px; */ color: #474A4F; /* padding: 5px; */ border-radius: 50%; /* border: 1px solid; */ border-color: transparent; width: 40px; background-color: transparent; transition: 0.2s; -webkit-transition: 0.2s; }

.df-lightbox-close:hover { color: black; }

.df-lightbox-close:before { top: 50%; position: absolute; -webkit-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); left: 50%; font-size: 24px; }

.df-lightbox-wrapper .df-container { position: absolute !important; width: 100%; top: 0; bottom: 0; height: auto !important; }

.df-container.df-xs .df-ui-controls .df-ui-btn.df-ui-next, .df-container.df-xs .df-ui-controls .df-ui-btn.df-ui-prev { /* display: none; */ }

.df-container.df-xs .df-ui-controls .df-ui-btn.df-ui-page { display: none !important; }

._df_button, ._df_thumb { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; display: inline-block; position: relative; cursor: pointer; }

._df_button { padding: 5px 15px; background-color: #474A4F; background: #0085ba; color: white; font-size: 14px; border-color: #0073aa #006799 #006799; cursor: pointer; border-width: 1px; border-style: solid; -webkit-appearance: none; -webkit-border-radius: 3px; border-radius: 3px; white-space: nowrap; }

._df_thumb { border: none; width: 140px; height: 200px; text-align: center; }

._df_thumb { cursor: pointer; /* background-color: #eee; */ display: inline-block; transform-style: preserve-3d; -moz-transform-style: preserve-3d; -webkit-transform-style: preserve-3d; box-sizing: border-box; -webkit-transition: 0.2s; perspective: 1200px; -moz-perspective: 1200px; margin: 30px 10px 10px; -webkit-perspective: 800px; }

._df_books ._df_thumb, ._df_books .df-dummy { width: 140px; height: 200px; text-align: center; display: inline-block; position: relative; margin: 20px 10px; /* clear: left; */ vertical-align: top; }

._df_book-cover { background-size: 100% 100%; height: 100%; width: 100%; transform-origin: left; top: 0; background-color: #eee; position: absolute; overflow: hidden; -webkit-transition: 0.3s; -moz-transition: 0.3s; -ms-transition: 0.3s; }

._df_thumb ._df_book-title { bottom: -100%; position: absolute; width: 100%; left: 0; padding: 5px; font-size: 12px; background: rgba(1, 163, 163, 0.87); background: rgba(60, 60, 60, 0.8); box-sizing: border-box; display: block; color: white; transition: 0.4s; -webkit-transition: 0.4s; opacity: 0; }

._df_thumb ._df_book-tag { float: left; padding: 0 4px 20px; background-color: rgba(0, 0, 0, 0.26); text-transform: uppercase; font-size: 10px; margin: -15px 0 0 2px; z-index: -1; color: rgba(255, 255, 255, 0.8); position: relative; }

._df_thumb:hover ._df_book-title, ._df_thumb-not-found ._df_book-title { opacity: 1; bottom: 0; }

._df_thumb:hover ._df_book-title i { /* font-size: 1em; */ }

._df_thumb:hover { /* box-shadow: 0 1px 10px rgba(0, 0, 0, 0.40); */ }

._df_thumb:hover ._df_book-cover { -webkit-transform: rotateY(-20deg); -moz-transform: rotateY(-20deg); -ms-transform: rotateY(-20deg); transform: rotateY(-20deg); }

._df_books { max-width: 960px; margin: 0 auto; text-align: center; position: relative; z-index: 1; padding: 20px 5px 150px; }

._df_books-break { color: rgba(255, 255, 255, 0.3); font-size: 14px; letter-spacing: 1px; font-weight: 600; margin-top: 25px; text-transform: uppercase; /* text-shadow: 0 -1px 0 rgba(85, 85, 85, 0.31); */ }

._df_books-break:after { content: " "; bottom: 0; width: 50px; height: 1px; background-color: rgba(255, 255, 255, 0.24); display: block; margin: 10px auto; }

.df-page-wrapper { -webkit-transform: translateZ(0); -moz-transform: translateZ(0); -ms-transform: translateZ(0); transform: translateZ(0); }

.df-container ::-webkit-scrollbar { width: 8px; }

.df-container ::-webkit-scrollbar-button { height: 8px; }

.df-container ::-webkit-scrollbar-thumb { background: rgba(0, 0, 0, 0.3); border: 0 none #ffffff; border-radius: 100px; /* margin-right: 5px; */ }

.df-container ::-webkit-scrollbar-thumb:hover { background: rgba(0, 0, 0, 0.5); }

.df-container ::-webkit-scrollbar-thumb:active { background: rgba(0, 0, 0, 0.5); }

.df-container ::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.2); border: 0 none #ffffff; border-radius: 100px; }

.df-container ::-webkit-scrollbar-track:hover { background: rgba(0, 0, 0, 0.2); }

.df-container ::-webkit-scrollbar-track:active { background: rgba(0, 0, 0, 0.15); }

.df-container ::-webkit-scrollbar-corner { background: transparent; }

.df-skipTransition { -webkit-transition: none !important; -moz-transition: none !important; -ms-transition: none !important; transition: none !important; }

.df-container.df-fullscreen { height: 100% !important; max-height: 100% !important; width: 100% !important; max-width: 100% !important; }

.df-container.df-double-internal .df-page-front { background-position: left; background-size: cover; }

.df-container.df-double-internal .df-page-back { background-position: right; background-size: cover; }

.customHtmlAnnotation iframe { position: absolute; top: 0; left: 0; width: 100% !important; height: 100% !important; }

/* ---------------------------------------------------------------------------- Components ------------------------------------------------------------------------- */
@media screen and (max-width: 767px) { .content-block { text-align: center; } }

.content-block__body { display: flex; height: 100%; flex-direction: column; }

.content-block__body--space-between { justify-content: space-between; }

.content-block__body--flex-end { justify-content: flex-end; }

.content-block__body .content-block__title { margin-top: 4rem; }

@media screen and (max-width: 767px) { .content-block__body .content-block__title { margin-top: 0; } }

.content-block__title + .content-block__content { margin-top: 3.5rem; }

@media screen and (max-width: 767px) { .content-block__title + .content-block__content { margin-top: 2rem; } }

.content-block__title + .content-block__subtitle { margin-top: 2.8rem; }

@media screen and (max-width: 767px) { .content-block__title + .content-block__subtitle { margin-top: 1.5rem; } }

.content-block__subtitle { margin-bottom: 1rem; }

.content-block__content { max-width: 37rem; }

@media screen and (max-width: 767px) { .content-block__content { margin: auto; } }

.content-block__content--wide { max-width: 44rem; }

.content-block__content--max { max-width: 100%; }

.content-block__meta { margin-top: 1.5rem; }

.content-block__cta { margin-top: 4.7rem; }

@media screen and (max-width: 767px) { .content-block__cta { margin-top: 2.5rem; margin-bottom: 2.5rem; } }

.content-block__img { max-width: 100%; }

.content-block__img-wrapper { display: flex; height: 100%; }

.content-block__img-wrapper--bottom { align-items: flex-end; }

.content-block__square-img { width: 100%; position: relative; top: 5rem; }

@media screen and (max-width: 767px) { .content-block__square-img { position: static; top: auto; } }

.content-block__form { margin-top: 5rem; }

.content-block__form__input { margin-bottom: 5rem; }

.input-field { appearance: none; border: none; border-bottom: 1px #979797 solid; padding: 1rem 0; font-size: 1.1rem; text-transform: uppercase; letter-spacing: .05rem; color: #474A4F; font-family: "Montserrat", sans-serif; font-weight: 300; }

.input-field:focus { border-bottom-color: #ABA3BC; }

.input-field--block { width: 100%; display: block; }

.footer { padding: 3rem 0; }

.footer__value { font-size: .9rem; color: #888888; letter-spacing: .1rem; text-decoration: none; }

.container { position: relative; max-width: 1600px; min-height: 100vh; margin: 0 auto; box-shadow: 0 0 3.5rem 0 rgba(0, 0, 0, 0.07); overflow: hidden; }

@media screen and (min-width: 1600.1px) { .container { max-width: 1600px; } }

.container:before { position: absolute; background-position: center bottom -0.2rem; background-repeat: no-repeat; background-size: contain; content: ''; width: 6rem; height: 22rem; top: 70%; right: -0.2rem; }

@media screen and (max-width: 767px) { .container:before { display: none; } }

.container--primary { background: #474A4F; }

.container--primary:before { background-image: url("../img/brand/ornament-5.svg"); }

.container--grey { background: #E5E6E7; }

.container--grey:before { background-image: url("../img/brand/ornament-4.svg"); }

.page { width: 100%; background-color: #fff; }

.main-content { outline: none; position: relative; }

.grid--full-height { height: 100%; }

@media screen and (max-width: 767px) { .grid--full-height { height: auto; } }

.col--flex > .grid { min-width: 100%; }

.no-flexbox .grid { display: block !important; min-width: 0; }

.grid { position: relative; box-sizing: border-box; display: flex; margin-left: -4rem; flex-direction: row; flex: 0 1 auto; flex-wrap: wrap; }

.no-flexbox .grid:after { content: ""; display: table; clear: both; }

.grid > * { box-sizing: border-box; padding-left: 4rem; position: relative; }

.no-flexbox .grid > * { float: left; }

.grid--no-gutter { margin-left: 0; }

.grid--no-gutter > * { padding-left: 0; }

.grid--v-top { align-items: flex-start; }

.grid--v-center { align-items: center; }

.grid--v-bottom { align-items: flex-end; }

.grid--v-stretch { align-items: stretch; }

.grid--v-baseline { align-items: stretch; }

.grid--align-start { justify-content: flex-start; }

.grid--align-end { justify-content: flex-end; }

.grid--align-center { justify-content: center; }

.grid--align-space-between { justify-content: space-between; }

.grid--align-space-around { justify-content: space-around; }

.col--fit { flex: 1 0; }

.col--flex { display: flex; }

.col--wide-content { margin-left: -4rem; margin-right: -4rem; }

.col--1-12 { flex-basis: 8.3333333333%; max-width: 8.3333333333%; min-width: 8.3333333333%; }

.no-flexbox .col--1-12 { width: 8.3333333333%; }

.col--1-6, .col--2-12 { flex-basis: 16.6666666667%; max-width: 16.6666666667%; min-width: 16.6666666667%; }

.no-flexbox .col--1-6, .no-flexbox .col--2-12 { width: 16.6666666667%; }

.col--1-4, .col--3-12 { flex-basis: 25%; max-width: 25%; min-width: 25%; }

.no-flexbox .col--1-4, .no-flexbox .col--3-12 { width: 25%; }

.col--1-3, .col--2-6, .col--4-12 { flex-basis: 33.3333333333%; max-width: 33.3333333333%; min-width: 33.3333333333%; }

.no-flexbox .col--1-3, .no-flexbox .col--2-6, .no-flexbox .col--4-12 { width: 33.3333333333%; }

.col--5-12 { flex-basis: 41.6666666667%; max-width: 41.6666666667%; min-width: 41.6666666667%; }

.no-flexbox .col--5-12 { width: 41.6666666667%; }

.col--1-2, .col--2-4, .col--3-6, .col--6-12 { flex-basis: 50%; max-width: 50%; min-width: 50%; }

.no-flexbox .col--1-2, .no-flexbox .col--2-4, .no-flexbox .col--3-6, .no-flexbox .col--6-12 { width: 50%; }

.col--7-12 { flex-basis: 58.3333333333%; max-width: 58.3333333333%; min-width: 58.3333333333%; }

.no-flexbox .col--7-12 { width: 58.3333333333%; }

.col--2-3, .col--4-6, .col--8-12 { flex-basis: 66.6666666667%; max-width: 66.6666666667%; min-width: 66.6666666667%; }

.no-flexbox .col--2-3, .no-flexbox .col--4-6, .no-flexbox .col--8-12 { width: 66.6666666667%; }

.col--3-4, .col--9-12 { flex-basis: 75%; max-width: 75%; min-width: 75%; }

.no-flexbox .col--3-4, .no-flexbox .col--9-12 { width: 75%; }

.col--5-6, .col--10-12 { flex-basis: 83.3333333333%; max-width: 83.3333333333%; min-width: 83.3333333333%; }

.no-flexbox .col--5-6, .no-flexbox .col--10-12 { width: 83.3333333333%; }

.col--11-12 { flex-basis: 91.6666666667%; max-width: 91.6666666667%; min-width: 91.6666666667%; }

.no-flexbox .col--11-12 { width: 91.6666666667%; }

.col--1-1, .col--2-2, .col--3-3, .col--4-4, .col--6-6, .col--12-12 { flex-basis: 100%; max-width: 100%; min-width: 100%; }

.no-flexbox .col--1-1, .no-flexbox .col--2-2, .no-flexbox .col--3-3, .no-flexbox .col--4-4, .no-flexbox .col--6-6, .no-flexbox .col--12-12 { width: 100%; }

.grid--is-stacked { margin-top: -4rem; }

.grid--is-stacked > * { margin-top: 4rem; }

.push--reset { margin-left: 0; }

.push--1-12 { margin-left: 8.3333333333%; }

.push--1-6, .push--2-12 { margin-left: 16.6666666667%; }

.push--1-4, .push--3-12 { margin-left: 25%; }

.push--1-3, .push--2-6, .push--4-12 { margin-left: 33.3333333333%; }

.push--5-12 { margin-left: 41.6666666667%; }

.push--1-2, .push--2-4, .push--3-6, .push--6-12 { margin-left: 50%; }

.push--7-12 { margin-left: 58.3333333333%; }

.push--2-3, .push--4-6, .push--8-12 { margin-left: 66.6666666667%; }

.push--3-4, .push--9-12 { margin-left: 75%; }

.push--5-6, .push--10-12 { margin-left: 83.3333333333%; }

.push--11-12 { margin-left: 91.6666666667%; }

.col--omega { margin-left: auto; }

@media screen and (min-width: 1024px) { .grid { position: relative; box-sizing: border-box; display: flex; margin-left: -4rem; flex-direction: row; flex: 0 1 auto; flex-wrap: wrap; }
  .no-flexbox .grid:after { content: ""; display: table; clear: both; }
  .grid > * { box-sizing: border-box; padding-left: 4rem; position: relative; }
  .no-flexbox .grid > * { float: left; }
  .grid--no-gutter--l { margin-left: 0; }
  .grid--no-gutter--l > * { padding-left: 0; }
  .grid--v-top--l { align-items: flex-start; }
  .grid--v-center--l { align-items: center; }
  .grid--v-bottom--l { align-items: flex-end; }
  .grid--v-stretch--l { align-items: stretch; }
  .grid--v-baseline--l { align-items: stretch; }
  .grid--align-start--l { justify-content: flex-start; }
  .grid--align-end--l { justify-content: flex-end; }
  .grid--align-center--l { justify-content: center; }
  .grid--align-space-between--l { justify-content: space-between; }
  .grid--align-space-around--l { justify-content: space-around; }
  .col--fit--l { flex: 1 0; }
  .col--flex--l { display: flex; }
  .col--wide-content--l { margin-left: -4rem; margin-right: -4rem; }
  .col--1-12--l { flex-basis: 8.3333333333%; max-width: 8.3333333333%; min-width: 8.3333333333%; }
  .no-flexbox .col--1-12--l { width: 8.3333333333%; }
  .col--1-6--l, .col--2-12--l { flex-basis: 16.6666666667%; max-width: 16.6666666667%; min-width: 16.6666666667%; }
  .no-flexbox .col--1-6--l, .no-flexbox .col--2-12--l { width: 16.6666666667%; }
  .col--1-4--l, .col--3-12--l { flex-basis: 25%; max-width: 25%; min-width: 25%; }
  .no-flexbox .col--1-4--l, .no-flexbox .col--3-12--l { width: 25%; }
  .col--1-3--l, .col--2-6--l, .col--4-12--l { flex-basis: 33.3333333333%; max-width: 33.3333333333%; min-width: 33.3333333333%; }
  .no-flexbox .col--1-3--l, .no-flexbox .col--2-6--l, .no-flexbox .col--4-12--l { width: 33.3333333333%; }
  .col--5-12--l { flex-basis: 41.6666666667%; max-width: 41.6666666667%; min-width: 41.6666666667%; }
  .no-flexbox .col--5-12--l { width: 41.6666666667%; }
  .col--1-2--l, .col--2-4--l, .col--3-6--l, .col--6-12--l { flex-basis: 50%; max-width: 50%; min-width: 50%; }
  .no-flexbox .col--1-2--l, .no-flexbox .col--2-4--l, .no-flexbox .col--3-6--l, .no-flexbox .col--6-12--l { width: 50%; }
  .col--7-12--l { flex-basis: 58.3333333333%; max-width: 58.3333333333%; min-width: 58.3333333333%; }
  .no-flexbox .col--7-12--l { width: 58.3333333333%; }
  .col--2-3--l, .col--4-6--l, .col--8-12--l { flex-basis: 66.6666666667%; max-width: 66.6666666667%; min-width: 66.6666666667%; }
  .no-flexbox .col--2-3--l, .no-flexbox .col--4-6--l, .no-flexbox .col--8-12--l { width: 66.6666666667%; }
  .col--3-4--l, .col--9-12--l { flex-basis: 75%; max-width: 75%; min-width: 75%; }
  .no-flexbox .col--3-4--l, .no-flexbox .col--9-12--l { width: 75%; }
  .col--5-6--l, .col--10-12--l { flex-basis: 83.3333333333%; max-width: 83.3333333333%; min-width: 83.3333333333%; }
  .no-flexbox .col--5-6--l, .no-flexbox .col--10-12--l { width: 83.3333333333%; }
  .col--11-12--l { flex-basis: 91.6666666667%; max-width: 91.6666666667%; min-width: 91.6666666667%; }
  .no-flexbox .col--11-12--l { width: 91.6666666667%; }
  .col--1-1--l, .col--2-2--l, .col--3-3--l, .col--4-4--l, .col--6-6--l, .col--12-12--l { flex-basis: 100%; max-width: 100%; min-width: 100%; }
  .no-flexbox .col--1-1--l, .no-flexbox .col--2-2--l, .no-flexbox .col--3-3--l, .no-flexbox .col--4-4--l, .no-flexbox .col--6-6--l, .no-flexbox .col--12-12--l { width: 100%; }
  .grid--is-stacked { margin-top: -4rem; }
  .grid--is-stacked > * { margin-top: 4rem; }
  .push--reset--l { margin-left: 0; }
  .push--1-12--l { margin-left: 8.3333333333%; }
  .push--1-6--l, .push--2-12--l { margin-left: 16.6666666667%; }
  .push--1-4--l, .push--3-12--l { margin-left: 25%; }
  .push--1-3--l, .push--2-6--l, .push--4-12--l { margin-left: 33.3333333333%; }
  .push--5-12--l { margin-left: 41.6666666667%; }
  .push--1-2--l, .push--2-4--l, .push--3-6--l, .push--6-12--l { margin-left: 50%; }
  .push--7-12--l { margin-left: 58.3333333333%; }
  .push--2-3--l, .push--4-6--l, .push--8-12--l { margin-left: 66.6666666667%; }
  .push--3-4--l, .push--9-12--l { margin-left: 75%; }
  .push--5-6--l, .push--10-12--l { margin-left: 83.3333333333%; }
  .push--11-12--l { margin-left: 91.6666666667%; }
  .col--omega--l { margin-left: auto; } }

@media screen and (min-width: 1601px) { .grid { position: relative; box-sizing: border-box; display: flex; margin-left: -4rem; flex-direction: row; flex: 0 1 auto; flex-wrap: wrap; }
  .no-flexbox .grid:after { content: ""; display: table; clear: both; }
  .grid > * { box-sizing: border-box; padding-left: 4rem; position: relative; }
  .no-flexbox .grid > * { float: left; }
  .grid--no-gutter--xl { margin-left: 0; }
  .grid--no-gutter--xl > * { padding-left: 0; }
  .grid--v-top--xl { align-items: flex-start; }
  .grid--v-center--xl { align-items: center; }
  .grid--v-bottom--xl { align-items: flex-end; }
  .grid--v-stretch--xl { align-items: stretch; }
  .grid--v-baseline--xl { align-items: stretch; }
  .grid--align-start--xl { justify-content: flex-start; }
  .grid--align-end--xl { justify-content: flex-end; }
  .grid--align-center--xl { justify-content: center; }
  .grid--align-space-between--xl { justify-content: space-between; }
  .grid--align-space-around--xl { justify-content: space-around; }
  .col--fit--xl { flex: 1 0; }
  .col--flex--xl { display: flex; }
  .col--wide-content--xl { margin-left: -4rem; margin-right: -4rem; }
  .col--1-12--xl { flex-basis: 8.3333333333%; max-width: 8.3333333333%; min-width: 8.3333333333%; }
  .no-flexbox .col--1-12--xl { width: 8.3333333333%; }
  .col--1-6--xl, .col--2-12--xl { flex-basis: 16.6666666667%; max-width: 16.6666666667%; min-width: 16.6666666667%; }
  .no-flexbox .col--1-6--xl, .no-flexbox .col--2-12--xl { width: 16.6666666667%; }
  .col--1-4--xl, .col--3-12--xl { flex-basis: 25%; max-width: 25%; min-width: 25%; }
  .no-flexbox .col--1-4--xl, .no-flexbox .col--3-12--xl { width: 25%; }
  .col--1-3--xl, .col--2-6--xl, .col--4-12--xl { flex-basis: 33.3333333333%; max-width: 33.3333333333%; min-width: 33.3333333333%; }
  .no-flexbox .col--1-3--xl, .no-flexbox .col--2-6--xl, .no-flexbox .col--4-12--xl { width: 33.3333333333%; }
  .col--5-12--xl { flex-basis: 41.6666666667%; max-width: 41.6666666667%; min-width: 41.6666666667%; }
  .no-flexbox .col--5-12--xl { width: 41.6666666667%; }
  .col--1-2--xl, .col--2-4--xl, .col--3-6--xl, .col--6-12--xl { flex-basis: 50%; max-width: 50%; min-width: 50%; }
  .no-flexbox .col--1-2--xl, .no-flexbox .col--2-4--xl, .no-flexbox .col--3-6--xl, .no-flexbox .col--6-12--xl { width: 50%; }
  .col--7-12--xl { flex-basis: 58.3333333333%; max-width: 58.3333333333%; min-width: 58.3333333333%; }
  .no-flexbox .col--7-12--xl { width: 58.3333333333%; }
  .col--2-3--xl, .col--4-6--xl, .col--8-12--xl { flex-basis: 66.6666666667%; max-width: 66.6666666667%; min-width: 66.6666666667%; }
  .no-flexbox .col--2-3--xl, .no-flexbox .col--4-6--xl, .no-flexbox .col--8-12--xl { width: 66.6666666667%; }
  .col--3-4--xl, .col--9-12--xl { flex-basis: 75%; max-width: 75%; min-width: 75%; }
  .no-flexbox .col--3-4--xl, .no-flexbox .col--9-12--xl { width: 75%; }
  .col--5-6--xl, .col--10-12--xl { flex-basis: 83.3333333333%; max-width: 83.3333333333%; min-width: 83.3333333333%; }
  .no-flexbox .col--5-6--xl, .no-flexbox .col--10-12--xl { width: 83.3333333333%; }
  .col--11-12--xl { flex-basis: 91.6666666667%; max-width: 91.6666666667%; min-width: 91.6666666667%; }
  .no-flexbox .col--11-12--xl { width: 91.6666666667%; }
  .col--1-1--xl, .col--2-2--xl, .col--3-3--xl, .col--4-4--xl, .col--6-6--xl, .col--12-12--xl { flex-basis: 100%; max-width: 100%; min-width: 100%; }
  .no-flexbox .col--1-1--xl, .no-flexbox .col--2-2--xl, .no-flexbox .col--3-3--xl, .no-flexbox .col--4-4--xl, .no-flexbox .col--6-6--xl, .no-flexbox .col--12-12--xl { width: 100%; }
  .grid--is-stacked { margin-top: -4rem; }
  .grid--is-stacked > * { margin-top: 4rem; }
  .push--reset--xl { margin-left: 0; }
  .push--1-12--xl { margin-left: 8.3333333333%; }
  .push--1-6--xl, .push--2-12--xl { margin-left: 16.6666666667%; }
  .push--1-4--xl, .push--3-12--xl { margin-left: 25%; }
  .push--1-3--xl, .push--2-6--xl, .push--4-12--xl { margin-left: 33.3333333333%; }
  .push--5-12--xl { margin-left: 41.6666666667%; }
  .push--1-2--xl, .push--2-4--xl, .push--3-6--xl, .push--6-12--xl { margin-left: 50%; }
  .push--7-12--xl { margin-left: 58.3333333333%; }
  .push--2-3--xl, .push--4-6--xl, .push--8-12--xl { margin-left: 66.6666666667%; }
  .push--3-4--xl, .push--9-12--xl { margin-left: 75%; }
  .push--5-6--xl, .push--10-12--xl { margin-left: 83.3333333333%; }
  .push--11-12--xl { margin-left: 91.6666666667%; }
  .col--omega--xl { margin-left: auto; } }

@media screen and (max-width: 1023px) { .grid { position: relative; box-sizing: border-box; display: flex; margin-left: -4rem; flex-direction: row; flex: 0 1 auto; flex-wrap: wrap; }
  .no-flexbox .grid:after { content: ""; display: table; clear: both; }
  .grid > * { box-sizing: border-box; padding-left: 4rem; position: relative; }
  .no-flexbox .grid > * { float: left; }
  .grid--no-gutter--m { margin-left: 0; }
  .grid--no-gutter--m > * { padding-left: 0; }
  .grid--v-top--m { align-items: flex-start; }
  .grid--v-center--m { align-items: center; }
  .grid--v-bottom--m { align-items: flex-end; }
  .grid--v-stretch--m { align-items: stretch; }
  .grid--v-baseline--m { align-items: stretch; }
  .grid--align-start--m { justify-content: flex-start; }
  .grid--align-end--m { justify-content: flex-end; }
  .grid--align-center--m { justify-content: center; }
  .grid--align-space-between--m { justify-content: space-between; }
  .grid--align-space-around--m { justify-content: space-around; }
  .col--fit--m { flex: 1 0; }
  .col--flex--m { display: flex; }
  .col--wide-content--m { margin-left: -4rem; margin-right: -4rem; }
  .col--1-12--m { flex-basis: 8.3333333333%; max-width: 8.3333333333%; min-width: 8.3333333333%; }
  .no-flexbox .col--1-12--m { width: 8.3333333333%; }
  .col--1-6--m, .col--2-12--m { flex-basis: 16.6666666667%; max-width: 16.6666666667%; min-width: 16.6666666667%; }
  .no-flexbox .col--1-6--m, .no-flexbox .col--2-12--m { width: 16.6666666667%; }
  .col--1-4--m, .col--3-12--m { flex-basis: 25%; max-width: 25%; min-width: 25%; }
  .no-flexbox .col--1-4--m, .no-flexbox .col--3-12--m { width: 25%; }
  .col--1-3--m, .col--2-6--m, .col--4-12--m { flex-basis: 33.3333333333%; max-width: 33.3333333333%; min-width: 33.3333333333%; }
  .no-flexbox .col--1-3--m, .no-flexbox .col--2-6--m, .no-flexbox .col--4-12--m { width: 33.3333333333%; }
  .col--5-12--m { flex-basis: 41.6666666667%; max-width: 41.6666666667%; min-width: 41.6666666667%; }
  .no-flexbox .col--5-12--m { width: 41.6666666667%; }
  .col--1-2--m, .col--2-4--m, .col--3-6--m, .col--6-12--m { flex-basis: 50%; max-width: 50%; min-width: 50%; }
  .no-flexbox .col--1-2--m, .no-flexbox .col--2-4--m, .no-flexbox .col--3-6--m, .no-flexbox .col--6-12--m { width: 50%; }
  .col--7-12--m { flex-basis: 58.3333333333%; max-width: 58.3333333333%; min-width: 58.3333333333%; }
  .no-flexbox .col--7-12--m { width: 58.3333333333%; }
  .col--2-3--m, .col--4-6--m, .col--8-12--m { flex-basis: 66.6666666667%; max-width: 66.6666666667%; min-width: 66.6666666667%; }
  .no-flexbox .col--2-3--m, .no-flexbox .col--4-6--m, .no-flexbox .col--8-12--m { width: 66.6666666667%; }
  .col--3-4--m, .col--9-12--m { flex-basis: 75%; max-width: 75%; min-width: 75%; }
  .no-flexbox .col--3-4--m, .no-flexbox .col--9-12--m { width: 75%; }
  .col--5-6--m, .col--10-12--m { flex-basis: 83.3333333333%; max-width: 83.3333333333%; min-width: 83.3333333333%; }
  .no-flexbox .col--5-6--m, .no-flexbox .col--10-12--m { width: 83.3333333333%; }
  .col--11-12--m { flex-basis: 91.6666666667%; max-width: 91.6666666667%; min-width: 91.6666666667%; }
  .no-flexbox .col--11-12--m { width: 91.6666666667%; }
  .col--1-1--m, .col--2-2--m, .col--3-3--m, .col--4-4--m, .col--6-6--m, .col--12-12--m { flex-basis: 100%; max-width: 100%; min-width: 100%; }
  .no-flexbox .col--1-1--m, .no-flexbox .col--2-2--m, .no-flexbox .col--3-3--m, .no-flexbox .col--4-4--m, .no-flexbox .col--6-6--m, .no-flexbox .col--12-12--m { width: 100%; }
  .grid--is-stacked { margin-top: -4rem; }
  .grid--is-stacked > * { margin-top: 4rem; }
  .push--reset--m { margin-left: 0; }
  .push--1-12--m { margin-left: 8.3333333333%; }
  .push--1-6--m, .push--2-12--m { margin-left: 16.6666666667%; }
  .push--1-4--m, .push--3-12--m { margin-left: 25%; }
  .push--1-3--m, .push--2-6--m, .push--4-12--m { margin-left: 33.3333333333%; }
  .push--5-12--m { margin-left: 41.6666666667%; }
  .push--1-2--m, .push--2-4--m, .push--3-6--m, .push--6-12--m { margin-left: 50%; }
  .push--7-12--m { margin-left: 58.3333333333%; }
  .push--2-3--m, .push--4-6--m, .push--8-12--m { margin-left: 66.6666666667%; }
  .push--3-4--m, .push--9-12--m { margin-left: 75%; }
  .push--5-6--m, .push--10-12--m { margin-left: 83.3333333333%; }
  .push--11-12--m { margin-left: 91.6666666667%; }
  .col--omega--m { margin-left: auto; } }

@media screen and (max-width: 767px) { .grid { position: relative; box-sizing: border-box; display: flex; margin-left: -1.5rem; flex-direction: row; flex: 0 1 auto; flex-wrap: wrap; }
  .no-flexbox .grid:after { content: ""; display: table; clear: both; }
  .grid > * { box-sizing: border-box; padding-left: 1.5rem; position: relative; }
  .no-flexbox .grid > * { float: left; }
  .grid--no-gutter--s { margin-left: 0; }
  .grid--no-gutter--s > * { padding-left: 0; }
  .grid--v-top--s { align-items: flex-start; }
  .grid--v-center--s { align-items: center; }
  .grid--v-bottom--s { align-items: flex-end; }
  .grid--v-stretch--s { align-items: stretch; }
  .grid--v-baseline--s { align-items: stretch; }
  .grid--align-start--s { justify-content: flex-start; }
  .grid--align-end--s { justify-content: flex-end; }
  .grid--align-center--s { justify-content: center; }
  .grid--align-space-between--s { justify-content: space-between; }
  .grid--align-space-around--s { justify-content: space-around; }
  .col--fit--s { flex: 1 0; }
  .col--flex--s { display: flex; }
  .col--wide-content--s { margin-left: -1.5rem; margin-right: -1.5rem; }
  .col--1-12--s { flex-basis: 8.3333333333%; max-width: 8.3333333333%; min-width: 8.3333333333%; }
  .no-flexbox .col--1-12--s { width: 8.3333333333%; }
  .col--1-6--s, .col--2-12--s { flex-basis: 16.6666666667%; max-width: 16.6666666667%; min-width: 16.6666666667%; }
  .no-flexbox .col--1-6--s, .no-flexbox .col--2-12--s { width: 16.6666666667%; }
  .col--1-4--s, .col--3-12--s { flex-basis: 25%; max-width: 25%; min-width: 25%; }
  .no-flexbox .col--1-4--s, .no-flexbox .col--3-12--s { width: 25%; }
  .col--1-3--s, .col--2-6--s, .col--4-12--s { flex-basis: 33.3333333333%; max-width: 33.3333333333%; min-width: 33.3333333333%; }
  .no-flexbox .col--1-3--s, .no-flexbox .col--2-6--s, .no-flexbox .col--4-12--s { width: 33.3333333333%; }
  .col--5-12--s { flex-basis: 41.6666666667%; max-width: 41.6666666667%; min-width: 41.6666666667%; }
  .no-flexbox .col--5-12--s { width: 41.6666666667%; }
  .col--1-2--s, .col--2-4--s, .col--3-6--s, .col--6-12--s { flex-basis: 50%; max-width: 50%; min-width: 50%; }
  .no-flexbox .col--1-2--s, .no-flexbox .col--2-4--s, .no-flexbox .col--3-6--s, .no-flexbox .col--6-12--s { width: 50%; }
  .col--7-12--s { flex-basis: 58.3333333333%; max-width: 58.3333333333%; min-width: 58.3333333333%; }
  .no-flexbox .col--7-12--s { width: 58.3333333333%; }
  .col--2-3--s, .col--4-6--s, .col--8-12--s { flex-basis: 66.6666666667%; max-width: 66.6666666667%; min-width: 66.6666666667%; }
  .no-flexbox .col--2-3--s, .no-flexbox .col--4-6--s, .no-flexbox .col--8-12--s { width: 66.6666666667%; }
  .col--3-4--s, .col--9-12--s { flex-basis: 75%; max-width: 75%; min-width: 75%; }
  .no-flexbox .col--3-4--s, .no-flexbox .col--9-12--s { width: 75%; }
  .col--5-6--s, .col--10-12--s { flex-basis: 83.3333333333%; max-width: 83.3333333333%; min-width: 83.3333333333%; }
  .no-flexbox .col--5-6--s, .no-flexbox .col--10-12--s { width: 83.3333333333%; }
  .col--11-12--s { flex-basis: 91.6666666667%; max-width: 91.6666666667%; min-width: 91.6666666667%; }
  .no-flexbox .col--11-12--s { width: 91.6666666667%; }
  .col--1-1--s, .col--2-2--s, .col--3-3--s, .col--4-4--s, .col--6-6--s, .col--12-12--s { flex-basis: 100%; max-width: 100%; min-width: 100%; }
  .no-flexbox .col--1-1--s, .no-flexbox .col--2-2--s, .no-flexbox .col--3-3--s, .no-flexbox .col--4-4--s, .no-flexbox .col--6-6--s, .no-flexbox .col--12-12--s { width: 100%; }
  .grid--is-stacked { margin-top: -1.5rem; }
  .grid--is-stacked > * { margin-top: 1.5rem; }
  .push--reset--s { margin-left: 0; }
  .push--1-12--s { margin-left: 8.3333333333%; }
  .push--1-6--s, .push--2-12--s { margin-left: 16.6666666667%; }
  .push--1-4--s, .push--3-12--s { margin-left: 25%; }
  .push--1-3--s, .push--2-6--s, .push--4-12--s { margin-left: 33.3333333333%; }
  .push--5-12--s { margin-left: 41.6666666667%; }
  .push--1-2--s, .push--2-4--s, .push--3-6--s, .push--6-12--s { margin-left: 50%; }
  .push--7-12--s { margin-left: 58.3333333333%; }
  .push--2-3--s, .push--4-6--s, .push--8-12--s { margin-left: 66.6666666667%; }
  .push--3-4--s, .push--9-12--s { margin-left: 75%; }
  .push--5-6--s, .push--10-12--s { margin-left: 83.3333333333%; }
  .push--11-12--s { margin-left: 91.6666666667%; }
  .col--omega--s { margin-left: auto; } }

@media screen and (max-width: 500px) { .grid { position: relative; box-sizing: border-box; display: flex; margin-left: -1.5rem; flex-direction: row; flex: 0 1 auto; flex-wrap: wrap; }
  .no-flexbox .grid:after { content: ""; display: table; clear: both; }
  .grid > * { box-sizing: border-box; padding-left: 1.5rem; position: relative; }
  .no-flexbox .grid > * { float: left; }
  .grid--no-gutter--xs { margin-left: 0; }
  .grid--no-gutter--xs > * { padding-left: 0; }
  .grid--v-top--xs { align-items: flex-start; }
  .grid--v-center--xs { align-items: center; }
  .grid--v-bottom--xs { align-items: flex-end; }
  .grid--v-stretch--xs { align-items: stretch; }
  .grid--v-baseline--xs { align-items: stretch; }
  .grid--align-start--xs { justify-content: flex-start; }
  .grid--align-end--xs { justify-content: flex-end; }
  .grid--align-center--xs { justify-content: center; }
  .grid--align-space-between--xs { justify-content: space-between; }
  .grid--align-space-around--xs { justify-content: space-around; }
  .col--fit--xs { flex: 1 0; }
  .col--flex--xs { display: flex; }
  .col--wide-content--xs { margin-left: -1.5rem; margin-right: -1.5rem; }
  .col--1-12--xs { flex-basis: 8.3333333333%; max-width: 8.3333333333%; min-width: 8.3333333333%; }
  .no-flexbox .col--1-12--xs { width: 8.3333333333%; }
  .col--1-6--xs, .col--2-12--xs { flex-basis: 16.6666666667%; max-width: 16.6666666667%; min-width: 16.6666666667%; }
  .no-flexbox .col--1-6--xs, .no-flexbox .col--2-12--xs { width: 16.6666666667%; }
  .col--1-4--xs, .col--3-12--xs { flex-basis: 25%; max-width: 25%; min-width: 25%; }
  .no-flexbox .col--1-4--xs, .no-flexbox .col--3-12--xs { width: 25%; }
  .col--1-3--xs, .col--2-6--xs, .col--4-12--xs { flex-basis: 33.3333333333%; max-width: 33.3333333333%; min-width: 33.3333333333%; }
  .no-flexbox .col--1-3--xs, .no-flexbox .col--2-6--xs, .no-flexbox .col--4-12--xs { width: 33.3333333333%; }
  .col--5-12--xs { flex-basis: 41.6666666667%; max-width: 41.6666666667%; min-width: 41.6666666667%; }
  .no-flexbox .col--5-12--xs { width: 41.6666666667%; }
  .col--1-2--xs, .col--2-4--xs, .col--3-6--xs, .col--6-12--xs { flex-basis: 50%; max-width: 50%; min-width: 50%; }
  .no-flexbox .col--1-2--xs, .no-flexbox .col--2-4--xs, .no-flexbox .col--3-6--xs, .no-flexbox .col--6-12--xs { width: 50%; }
  .col--7-12--xs { flex-basis: 58.3333333333%; max-width: 58.3333333333%; min-width: 58.3333333333%; }
  .no-flexbox .col--7-12--xs { width: 58.3333333333%; }
  .col--2-3--xs, .col--4-6--xs, .col--8-12--xs { flex-basis: 66.6666666667%; max-width: 66.6666666667%; min-width: 66.6666666667%; }
  .no-flexbox .col--2-3--xs, .no-flexbox .col--4-6--xs, .no-flexbox .col--8-12--xs { width: 66.6666666667%; }
  .col--3-4--xs, .col--9-12--xs { flex-basis: 75%; max-width: 75%; min-width: 75%; }
  .no-flexbox .col--3-4--xs, .no-flexbox .col--9-12--xs { width: 75%; }
  .col--5-6--xs, .col--10-12--xs { flex-basis: 83.3333333333%; max-width: 83.3333333333%; min-width: 83.3333333333%; }
  .no-flexbox .col--5-6--xs, .no-flexbox .col--10-12--xs { width: 83.3333333333%; }
  .col--11-12--xs { flex-basis: 91.6666666667%; max-width: 91.6666666667%; min-width: 91.6666666667%; }
  .no-flexbox .col--11-12--xs { width: 91.6666666667%; }
  .col--1-1--xs, .col--2-2--xs, .col--3-3--xs, .col--4-4--xs, .col--6-6--xs, .col--12-12--xs { flex-basis: 100%; max-width: 100%; min-width: 100%; }
  .no-flexbox .col--1-1--xs, .no-flexbox .col--2-2--xs, .no-flexbox .col--3-3--xs, .no-flexbox .col--4-4--xs, .no-flexbox .col--6-6--xs, .no-flexbox .col--12-12--xs { width: 100%; }
  .grid--is-stacked { margin-top: -1.5rem; }
  .grid--is-stacked > * { margin-top: 1.5rem; }
  .push--reset--xs { margin-left: 0; }
  .push--1-12--xs { margin-left: 8.3333333333%; }
  .push--1-6--xs, .push--2-12--xs { margin-left: 16.6666666667%; }
  .push--1-4--xs, .push--3-12--xs { margin-left: 25%; }
  .push--1-3--xs, .push--2-6--xs, .push--4-12--xs { margin-left: 33.3333333333%; }
  .push--5-12--xs { margin-left: 41.6666666667%; }
  .push--1-2--xs, .push--2-4--xs, .push--3-6--xs, .push--6-12--xs { margin-left: 50%; }
  .push--7-12--xs { margin-left: 58.3333333333%; }
  .push--2-3--xs, .push--4-6--xs, .push--8-12--xs { margin-left: 66.6666666667%; }
  .push--3-4--xs, .push--9-12--xs { margin-left: 75%; }
  .push--5-6--xs, .push--10-12--xs { margin-left: 83.3333333333%; }
  .push--11-12--xs { margin-left: 91.6666666667%; }
  .col--omega--xs { margin-left: auto; } }

.form-grid { position: relative; box-sizing: border-box; display: flex; margin-left: -1.5rem; flex-direction: row; flex: 0 1 auto; flex-wrap: wrap; }

.no-flexbox .form-grid:after { content: ""; display: table; clear: both; }

.form-grid > * { box-sizing: border-box; padding-left: 1.5rem; position: relative; }

.no-flexbox .form-grid > * { float: left; }

.form-grid--no-gutter { margin-left: 0; }

.form-grid--no-gutter > * { padding-left: 0; }

.form-grid--v-top { align-items: flex-start; }

.form-grid--v-center { align-items: center; }

.form-grid--v-bottom { align-items: flex-end; }

.form-grid--v-stretch { align-items: stretch; }

.form-grid--v-baseline { align-items: stretch; }

.form-grid--align-start { justify-content: flex-start; }

.form-grid--align-end { justify-content: flex-end; }

.form-grid--align-center { justify-content: center; }

.form-grid--align-space-between { justify-content: space-between; }

.form-grid--align-space-around { justify-content: space-around; }

.form-col--fit { flex: 1 0; }

.form-col--flex { display: flex; }

.form-col--wide-content { margin-left: -1.5rem; margin-right: -1.5rem; }

.form-col--1-12 { flex-basis: 8.3333333333%; max-width: 8.3333333333%; min-width: 8.3333333333%; }

.no-flexbox .form-col--1-12 { width: 8.3333333333%; }

.form-col--1-6, .form-col--2-12 { flex-basis: 16.6666666667%; max-width: 16.6666666667%; min-width: 16.6666666667%; }

.no-flexbox .form-col--1-6, .no-flexbox .form-col--2-12 { width: 16.6666666667%; }

.form-col--1-4, .form-col--3-12 { flex-basis: 25%; max-width: 25%; min-width: 25%; }

.no-flexbox .form-col--1-4, .no-flexbox .form-col--3-12 { width: 25%; }

.form-col--1-3, .form-col--2-6, .form-col--4-12 { flex-basis: 33.3333333333%; max-width: 33.3333333333%; min-width: 33.3333333333%; }

.no-flexbox .form-col--1-3, .no-flexbox .form-col--2-6, .no-flexbox .form-col--4-12 { width: 33.3333333333%; }

.form-col--5-12 { flex-basis: 41.6666666667%; max-width: 41.6666666667%; min-width: 41.6666666667%; }

.no-flexbox .form-col--5-12 { width: 41.6666666667%; }

.form-col--1-2, .form-col--2-4, .form-col--3-6, .form-col--6-12 { flex-basis: 50%; max-width: 50%; min-width: 50%; }

.no-flexbox .form-col--1-2, .no-flexbox .form-col--2-4, .no-flexbox .form-col--3-6, .no-flexbox .form-col--6-12 { width: 50%; }

.form-col--7-12 { flex-basis: 58.3333333333%; max-width: 58.3333333333%; min-width: 58.3333333333%; }

.no-flexbox .form-col--7-12 { width: 58.3333333333%; }

.form-col--2-3, .form-col--4-6, .form-col--8-12 { flex-basis: 66.6666666667%; max-width: 66.6666666667%; min-width: 66.6666666667%; }

.no-flexbox .form-col--2-3, .no-flexbox .form-col--4-6, .no-flexbox .form-col--8-12 { width: 66.6666666667%; }

.form-col--3-4, .form-col--9-12 { flex-basis: 75%; max-width: 75%; min-width: 75%; }

.no-flexbox .form-col--3-4, .no-flexbox .form-col--9-12 { width: 75%; }

.form-col--5-6, .form-col--10-12 { flex-basis: 83.3333333333%; max-width: 83.3333333333%; min-width: 83.3333333333%; }

.no-flexbox .form-col--5-6, .no-flexbox .form-col--10-12 { width: 83.3333333333%; }

.form-col--11-12 { flex-basis: 91.6666666667%; max-width: 91.6666666667%; min-width: 91.6666666667%; }

.no-flexbox .form-col--11-12 { width: 91.6666666667%; }

.form-col--1-1, .form-col--2-2, .form-col--3-3, .form-col--4-4, .form-col--6-6, .form-col--12-12 { flex-basis: 100%; max-width: 100%; min-width: 100%; }

.no-flexbox .form-col--1-1, .no-flexbox .form-col--2-2, .no-flexbox .form-col--3-3, .no-flexbox .form-col--4-4, .no-flexbox .form-col--6-6, .no-flexbox .form-col--12-12 { width: 100%; }

.form-grid--is-stacked { margin-top: -1.5rem; }

.form-grid--is-stacked > * { margin-top: 1.5rem; }

.form-push--reset { margin-left: 0; }

.form-push--1-12 { margin-left: 8.3333333333%; }

.form-push--1-6, .form-push--2-12 { margin-left: 16.6666666667%; }

.form-push--1-4, .form-push--3-12 { margin-left: 25%; }

.form-push--1-3, .form-push--2-6, .form-push--4-12 { margin-left: 33.3333333333%; }

.form-push--5-12 { margin-left: 41.6666666667%; }

.form-push--1-2, .form-push--2-4, .form-push--3-6, .form-push--6-12 { margin-left: 50%; }

.form-push--7-12 { margin-left: 58.3333333333%; }

.form-push--2-3, .form-push--4-6, .form-push--8-12 { margin-left: 66.6666666667%; }

.form-push--3-4, .form-push--9-12 { margin-left: 75%; }

.form-push--5-6, .form-push--10-12 { margin-left: 83.3333333333%; }

.form-push--11-12 { margin-left: 91.6666666667%; }

.form-col--omega { margin-left: auto; }

@media screen and (max-width: 767px) { .form-grid { position: relative; box-sizing: border-box; display: flex; margin-left: -1.5rem; flex-direction: row; flex: 0 1 auto; flex-wrap: wrap; }
  .no-flexbox .form-grid:after { content: ""; display: table; clear: both; }
  .form-grid > * { box-sizing: border-box; padding-left: 1.5rem; position: relative; }
  .no-flexbox .form-grid > * { float: left; }
  .form-grid--no-gutter--s { margin-left: 0; }
  .form-grid--no-gutter--s > * { padding-left: 0; }
  .form-grid--v-top--s { align-items: flex-start; }
  .form-grid--v-center--s { align-items: center; }
  .form-grid--v-bottom--s { align-items: flex-end; }
  .form-grid--v-stretch--s { align-items: stretch; }
  .form-grid--v-baseline--s { align-items: stretch; }
  .form-grid--align-start--s { justify-content: flex-start; }
  .form-grid--align-end--s { justify-content: flex-end; }
  .form-grid--align-center--s { justify-content: center; }
  .form-grid--align-space-between--s { justify-content: space-between; }
  .form-grid--align-space-around--s { justify-content: space-around; }
  .form-col--fit--s { flex: 1 0; }
  .form-col--flex--s { display: flex; }
  .form-col--wide-content--s { margin-left: -1.5rem; margin-right: -1.5rem; }
  .form-col--1-12--s { flex-basis: 8.3333333333%; max-width: 8.3333333333%; min-width: 8.3333333333%; }
  .no-flexbox .form-col--1-12--s { width: 8.3333333333%; }
  .form-col--1-6--s, .form-col--2-12--s { flex-basis: 16.6666666667%; max-width: 16.6666666667%; min-width: 16.6666666667%; }
  .no-flexbox .form-col--1-6--s, .no-flexbox .form-col--2-12--s { width: 16.6666666667%; }
  .form-col--1-4--s, .form-col--3-12--s { flex-basis: 25%; max-width: 25%; min-width: 25%; }
  .no-flexbox .form-col--1-4--s, .no-flexbox .form-col--3-12--s { width: 25%; }
  .form-col--1-3--s, .form-col--2-6--s, .form-col--4-12--s { flex-basis: 33.3333333333%; max-width: 33.3333333333%; min-width: 33.3333333333%; }
  .no-flexbox .form-col--1-3--s, .no-flexbox .form-col--2-6--s, .no-flexbox .form-col--4-12--s { width: 33.3333333333%; }
  .form-col--5-12--s { flex-basis: 41.6666666667%; max-width: 41.6666666667%; min-width: 41.6666666667%; }
  .no-flexbox .form-col--5-12--s { width: 41.6666666667%; }
  .form-col--1-2--s, .form-col--2-4--s, .form-col--3-6--s, .form-col--6-12--s { flex-basis: 50%; max-width: 50%; min-width: 50%; }
  .no-flexbox .form-col--1-2--s, .no-flexbox .form-col--2-4--s, .no-flexbox .form-col--3-6--s, .no-flexbox .form-col--6-12--s { width: 50%; }
  .form-col--7-12--s { flex-basis: 58.3333333333%; max-width: 58.3333333333%; min-width: 58.3333333333%; }
  .no-flexbox .form-col--7-12--s { width: 58.3333333333%; }
  .form-col--2-3--s, .form-col--4-6--s, .form-col--8-12--s { flex-basis: 66.6666666667%; max-width: 66.6666666667%; min-width: 66.6666666667%; }
  .no-flexbox .form-col--2-3--s, .no-flexbox .form-col--4-6--s, .no-flexbox .form-col--8-12--s { width: 66.6666666667%; }
  .form-col--3-4--s, .form-col--9-12--s { flex-basis: 75%; max-width: 75%; min-width: 75%; }
  .no-flexbox .form-col--3-4--s, .no-flexbox .form-col--9-12--s { width: 75%; }
  .form-col--5-6--s, .form-col--10-12--s { flex-basis: 83.3333333333%; max-width: 83.3333333333%; min-width: 83.3333333333%; }
  .no-flexbox .form-col--5-6--s, .no-flexbox .form-col--10-12--s { width: 83.3333333333%; }
  .form-col--11-12--s { flex-basis: 91.6666666667%; max-width: 91.6666666667%; min-width: 91.6666666667%; }
  .no-flexbox .form-col--11-12--s { width: 91.6666666667%; }
  .form-col--1-1--s, .form-col--2-2--s, .form-col--3-3--s, .form-col--4-4--s, .form-col--6-6--s, .form-col--12-12--s { flex-basis: 100%; max-width: 100%; min-width: 100%; }
  .no-flexbox .form-col--1-1--s, .no-flexbox .form-col--2-2--s, .no-flexbox .form-col--3-3--s, .no-flexbox .form-col--4-4--s, .no-flexbox .form-col--6-6--s, .no-flexbox .form-col--12-12--s { width: 100%; }
  .form-grid--is-stacked { margin-top: -1.5rem; }
  .form-grid--is-stacked > * { margin-top: 1.5rem; }
  .form-push--reset--s { margin-left: 0; }
  .form-push--1-12--s { margin-left: 8.3333333333%; }
  .form-push--1-6--s, .form-push--2-12--s { margin-left: 16.6666666667%; }
  .form-push--1-4--s, .form-push--3-12--s { margin-left: 25%; }
  .form-push--1-3--s, .form-push--2-6--s, .form-push--4-12--s { margin-left: 33.3333333333%; }
  .form-push--5-12--s { margin-left: 41.6666666667%; }
  .form-push--1-2--s, .form-push--2-4--s, .form-push--3-6--s, .form-push--6-12--s { margin-left: 50%; }
  .form-push--7-12--s { margin-left: 58.3333333333%; }
  .form-push--2-3--s, .form-push--4-6--s, .form-push--8-12--s { margin-left: 66.6666666667%; }
  .form-push--3-4--s, .form-push--9-12--s { margin-left: 75%; }
  .form-push--5-6--s, .form-push--10-12--s { margin-left: 83.3333333333%; }
  .form-push--11-12--s { margin-left: 91.6666666667%; }
  .form-col--omega--s { margin-left: auto; } }

.span-grid { position: relative; box-sizing: border-box; display: flex; margin-left: 0rem; flex-direction: row; flex: 0 1 auto; flex-wrap: wrap; }

.no-flexbox .span-grid:after { content: ""; display: table; clear: both; }

.span-grid > * { box-sizing: border-box; padding-left: 0rem; position: relative; }

.no-flexbox .span-grid > * { float: left; }

.span-grid--no-gutter { margin-left: 0; }

.span-grid--no-gutter > * { padding-left: 0; }

.span-grid--v-top { align-items: flex-start; }

.span-grid--v-center { align-items: center; }

.span-grid--v-bottom { align-items: flex-end; }

.span-grid--v-stretch { align-items: stretch; }

.span-grid--v-baseline { align-items: stretch; }

.span-grid--align-start { justify-content: flex-start; }

.span-grid--align-end { justify-content: flex-end; }

.span-grid--align-center { justify-content: center; }

.span-grid--align-space-between { justify-content: space-between; }

.span-grid--align-space-around { justify-content: space-around; }

.span--fit { flex: 1 0; }

.span--flex { display: flex; }

.span--wide-content { margin-left: 0rem; margin-right: 0rem; }

.span--1-12 { flex-basis: 8.3333333333%; max-width: 8.3333333333%; min-width: 8.3333333333%; }

.no-flexbox .span--1-12 { width: 8.3333333333%; }

.span--1-6, .span--2-12 { flex-basis: 16.6666666667%; max-width: 16.6666666667%; min-width: 16.6666666667%; }

.no-flexbox .span--1-6, .no-flexbox .span--2-12 { width: 16.6666666667%; }

.span--1-4, .span--3-12 { flex-basis: 25%; max-width: 25%; min-width: 25%; }

.no-flexbox .span--1-4, .no-flexbox .span--3-12 { width: 25%; }

.span--1-3, .span--2-6, .span--4-12 { flex-basis: 33.3333333333%; max-width: 33.3333333333%; min-width: 33.3333333333%; }

.no-flexbox .span--1-3, .no-flexbox .span--2-6, .no-flexbox .span--4-12 { width: 33.3333333333%; }

.span--5-12 { flex-basis: 41.6666666667%; max-width: 41.6666666667%; min-width: 41.6666666667%; }

.no-flexbox .span--5-12 { width: 41.6666666667%; }

.span--1-2, .span--2-4, .span--3-6, .span--6-12 { flex-basis: 50%; max-width: 50%; min-width: 50%; }

.no-flexbox .span--1-2, .no-flexbox .span--2-4, .no-flexbox .span--3-6, .no-flexbox .span--6-12 { width: 50%; }

.span--7-12 { flex-basis: 58.3333333333%; max-width: 58.3333333333%; min-width: 58.3333333333%; }

.no-flexbox .span--7-12 { width: 58.3333333333%; }

.span--2-3, .span--4-6, .span--8-12 { flex-basis: 66.6666666667%; max-width: 66.6666666667%; min-width: 66.6666666667%; }

.no-flexbox .span--2-3, .no-flexbox .span--4-6, .no-flexbox .span--8-12 { width: 66.6666666667%; }

.span--3-4, .span--9-12 { flex-basis: 75%; max-width: 75%; min-width: 75%; }

.no-flexbox .span--3-4, .no-flexbox .span--9-12 { width: 75%; }

.span--5-6, .span--10-12 { flex-basis: 83.3333333333%; max-width: 83.3333333333%; min-width: 83.3333333333%; }

.no-flexbox .span--5-6, .no-flexbox .span--10-12 { width: 83.3333333333%; }

.span--11-12 { flex-basis: 91.6666666667%; max-width: 91.6666666667%; min-width: 91.6666666667%; }

.no-flexbox .span--11-12 { width: 91.6666666667%; }

.span--1-1, .span--2-2, .span--3-3, .span--4-4, .span--6-6, .span--12-12 { flex-basis: 100%; max-width: 100%; min-width: 100%; }

.no-flexbox .span--1-1, .no-flexbox .span--2-2, .no-flexbox .span--3-3, .no-flexbox .span--4-4, .no-flexbox .span--6-6, .no-flexbox .span--12-12 { width: 100%; }

.span-grid--is-stacked { margin-top: 0rem; }

.span-grid--is-stacked > * { margin-top: 0rem; }

.push--reset { margin-left: 0; }

.push--1-12 { margin-left: 8.3333333333%; }

.push--1-6, .push--2-12 { margin-left: 16.6666666667%; }

.push--1-4, .push--3-12 { margin-left: 25%; }

.push--1-3, .push--2-6, .push--4-12 { margin-left: 33.3333333333%; }

.push--5-12 { margin-left: 41.6666666667%; }

.push--1-2, .push--2-4, .push--3-6, .push--6-12 { margin-left: 50%; }

.push--7-12 { margin-left: 58.3333333333%; }

.push--2-3, .push--4-6, .push--8-12 { margin-left: 66.6666666667%; }

.push--3-4, .push--9-12 { margin-left: 75%; }

.push--5-6, .push--10-12 { margin-left: 83.3333333333%; }

.push--11-12 { margin-left: 91.6666666667%; }

.span--omega { margin-left: auto; }

@media screen and (max-width: 1023px) { .span-grid { position: relative; box-sizing: border-box; display: flex; margin-left: 0rem; flex-direction: row; flex: 0 1 auto; flex-wrap: wrap; }
  .no-flexbox .span-grid:after { content: ""; display: table; clear: both; }
  .span-grid > * { box-sizing: border-box; padding-left: 0rem; position: relative; }
  .no-flexbox .span-grid > * { float: left; }
  .span-grid--no-gutter--m { margin-left: 0; }
  .span-grid--no-gutter--m > * { padding-left: 0; }
  .span-grid--v-top--m { align-items: flex-start; }
  .span-grid--v-center--m { align-items: center; }
  .span-grid--v-bottom--m { align-items: flex-end; }
  .span-grid--v-stretch--m { align-items: stretch; }
  .span-grid--v-baseline--m { align-items: stretch; }
  .span-grid--align-start--m { justify-content: flex-start; }
  .span-grid--align-end--m { justify-content: flex-end; }
  .span-grid--align-center--m { justify-content: center; }
  .span-grid--align-space-between--m { justify-content: space-between; }
  .span-grid--align-space-around--m { justify-content: space-around; }
  .span--fit--m { flex: 1 0; }
  .span--flex--m { display: flex; }
  .span--wide-content--m { margin-left: 0rem; margin-right: 0rem; }
  .span--1-12--m { flex-basis: 8.3333333333%; max-width: 8.3333333333%; min-width: 8.3333333333%; }
  .no-flexbox .span--1-12--m { width: 8.3333333333%; }
  .span--1-6--m, .span--2-12--m { flex-basis: 16.6666666667%; max-width: 16.6666666667%; min-width: 16.6666666667%; }
  .no-flexbox .span--1-6--m, .no-flexbox .span--2-12--m { width: 16.6666666667%; }
  .span--1-4--m, .span--3-12--m { flex-basis: 25%; max-width: 25%; min-width: 25%; }
  .no-flexbox .span--1-4--m, .no-flexbox .span--3-12--m { width: 25%; }
  .span--1-3--m, .span--2-6--m, .span--4-12--m { flex-basis: 33.3333333333%; max-width: 33.3333333333%; min-width: 33.3333333333%; }
  .no-flexbox .span--1-3--m, .no-flexbox .span--2-6--m, .no-flexbox .span--4-12--m { width: 33.3333333333%; }
  .span--5-12--m { flex-basis: 41.6666666667%; max-width: 41.6666666667%; min-width: 41.6666666667%; }
  .no-flexbox .span--5-12--m { width: 41.6666666667%; }
  .span--1-2--m, .span--2-4--m, .span--3-6--m, .span--6-12--m { flex-basis: 50%; max-width: 50%; min-width: 50%; }
  .no-flexbox .span--1-2--m, .no-flexbox .span--2-4--m, .no-flexbox .span--3-6--m, .no-flexbox .span--6-12--m { width: 50%; }
  .span--7-12--m { flex-basis: 58.3333333333%; max-width: 58.3333333333%; min-width: 58.3333333333%; }
  .no-flexbox .span--7-12--m { width: 58.3333333333%; }
  .span--2-3--m, .span--4-6--m, .span--8-12--m { flex-basis: 66.6666666667%; max-width: 66.6666666667%; min-width: 66.6666666667%; }
  .no-flexbox .span--2-3--m, .no-flexbox .span--4-6--m, .no-flexbox .span--8-12--m { width: 66.6666666667%; }
  .span--3-4--m, .span--9-12--m { flex-basis: 75%; max-width: 75%; min-width: 75%; }
  .no-flexbox .span--3-4--m, .no-flexbox .span--9-12--m { width: 75%; }
  .span--5-6--m, .span--10-12--m { flex-basis: 83.3333333333%; max-width: 83.3333333333%; min-width: 83.3333333333%; }
  .no-flexbox .span--5-6--m, .no-flexbox .span--10-12--m { width: 83.3333333333%; }
  .span--11-12--m { flex-basis: 91.6666666667%; max-width: 91.6666666667%; min-width: 91.6666666667%; }
  .no-flexbox .span--11-12--m { width: 91.6666666667%; }
  .span--1-1--m, .span--2-2--m, .span--3-3--m, .span--4-4--m, .span--6-6--m, .span--12-12--m { flex-basis: 100%; max-width: 100%; min-width: 100%; }
  .no-flexbox .span--1-1--m, .no-flexbox .span--2-2--m, .no-flexbox .span--3-3--m, .no-flexbox .span--4-4--m, .no-flexbox .span--6-6--m, .no-flexbox .span--12-12--m { width: 100%; }
  .span-grid--is-stacked { margin-top: 0rem; }
  .span-grid--is-stacked > * { margin-top: 0rem; }
  .push--reset--m { margin-left: 0; }
  .push--1-12--m { margin-left: 8.3333333333%; }
  .push--1-6--m, .push--2-12--m { margin-left: 16.6666666667%; }
  .push--1-4--m, .push--3-12--m { margin-left: 25%; }
  .push--1-3--m, .push--2-6--m, .push--4-12--m { margin-left: 33.3333333333%; }
  .push--5-12--m { margin-left: 41.6666666667%; }
  .push--1-2--m, .push--2-4--m, .push--3-6--m, .push--6-12--m { margin-left: 50%; }
  .push--7-12--m { margin-left: 58.3333333333%; }
  .push--2-3--m, .push--4-6--m, .push--8-12--m { margin-left: 66.6666666667%; }
  .push--3-4--m, .push--9-12--m { margin-left: 75%; }
  .push--5-6--m, .push--10-12--m { margin-left: 83.3333333333%; }
  .push--11-12--m { margin-left: 91.6666666667%; }
  .span--omega--m { margin-left: auto; } }

@media screen and (max-width: 767px) { .span-grid { position: relative; box-sizing: border-box; display: flex; margin-left: 0rem; flex-direction: row; flex: 0 1 auto; flex-wrap: wrap; }
  .no-flexbox .span-grid:after { content: ""; display: table; clear: both; }
  .span-grid > * { box-sizing: border-box; padding-left: 0rem; position: relative; }
  .no-flexbox .span-grid > * { float: left; }
  .span-grid--no-gutter--s { margin-left: 0; }
  .span-grid--no-gutter--s > * { padding-left: 0; }
  .span-grid--v-top--s { align-items: flex-start; }
  .span-grid--v-center--s { align-items: center; }
  .span-grid--v-bottom--s { align-items: flex-end; }
  .span-grid--v-stretch--s { align-items: stretch; }
  .span-grid--v-baseline--s { align-items: stretch; }
  .span-grid--align-start--s { justify-content: flex-start; }
  .span-grid--align-end--s { justify-content: flex-end; }
  .span-grid--align-center--s { justify-content: center; }
  .span-grid--align-space-between--s { justify-content: space-between; }
  .span-grid--align-space-around--s { justify-content: space-around; }
  .span--fit--s { flex: 1 0; }
  .span--flex--s { display: flex; }
  .span--wide-content--s { margin-left: 0rem; margin-right: 0rem; }
  .span--1-12--s { flex-basis: 8.3333333333%; max-width: 8.3333333333%; min-width: 8.3333333333%; }
  .no-flexbox .span--1-12--s { width: 8.3333333333%; }
  .span--1-6--s, .span--2-12--s { flex-basis: 16.6666666667%; max-width: 16.6666666667%; min-width: 16.6666666667%; }
  .no-flexbox .span--1-6--s, .no-flexbox .span--2-12--s { width: 16.6666666667%; }
  .span--1-4--s, .span--3-12--s { flex-basis: 25%; max-width: 25%; min-width: 25%; }
  .no-flexbox .span--1-4--s, .no-flexbox .span--3-12--s { width: 25%; }
  .span--1-3--s, .span--2-6--s, .span--4-12--s { flex-basis: 33.3333333333%; max-width: 33.3333333333%; min-width: 33.3333333333%; }
  .no-flexbox .span--1-3--s, .no-flexbox .span--2-6--s, .no-flexbox .span--4-12--s { width: 33.3333333333%; }
  .span--5-12--s { flex-basis: 41.6666666667%; max-width: 41.6666666667%; min-width: 41.6666666667%; }
  .no-flexbox .span--5-12--s { width: 41.6666666667%; }
  .span--1-2--s, .span--2-4--s, .span--3-6--s, .span--6-12--s { flex-basis: 50%; max-width: 50%; min-width: 50%; }
  .no-flexbox .span--1-2--s, .no-flexbox .span--2-4--s, .no-flexbox .span--3-6--s, .no-flexbox .span--6-12--s { width: 50%; }
  .span--7-12--s { flex-basis: 58.3333333333%; max-width: 58.3333333333%; min-width: 58.3333333333%; }
  .no-flexbox .span--7-12--s { width: 58.3333333333%; }
  .span--2-3--s, .span--4-6--s, .span--8-12--s { flex-basis: 66.6666666667%; max-width: 66.6666666667%; min-width: 66.6666666667%; }
  .no-flexbox .span--2-3--s, .no-flexbox .span--4-6--s, .no-flexbox .span--8-12--s { width: 66.6666666667%; }
  .span--3-4--s, .span--9-12--s { flex-basis: 75%; max-width: 75%; min-width: 75%; }
  .no-flexbox .span--3-4--s, .no-flexbox .span--9-12--s { width: 75%; }
  .span--5-6--s, .span--10-12--s { flex-basis: 83.3333333333%; max-width: 83.3333333333%; min-width: 83.3333333333%; }
  .no-flexbox .span--5-6--s, .no-flexbox .span--10-12--s { width: 83.3333333333%; }
  .span--11-12--s { flex-basis: 91.6666666667%; max-width: 91.6666666667%; min-width: 91.6666666667%; }
  .no-flexbox .span--11-12--s { width: 91.6666666667%; }
  .span--1-1--s, .span--2-2--s, .span--3-3--s, .span--4-4--s, .span--6-6--s, .span--12-12--s { flex-basis: 100%; max-width: 100%; min-width: 100%; }
  .no-flexbox .span--1-1--s, .no-flexbox .span--2-2--s, .no-flexbox .span--3-3--s, .no-flexbox .span--4-4--s, .no-flexbox .span--6-6--s, .no-flexbox .span--12-12--s { width: 100%; }
  .span-grid--is-stacked { margin-top: 0rem; }
  .span-grid--is-stacked > * { margin-top: 0rem; }
  .push--reset--s { margin-left: 0; }
  .push--1-12--s { margin-left: 8.3333333333%; }
  .push--1-6--s, .push--2-12--s { margin-left: 16.6666666667%; }
  .push--1-4--s, .push--3-12--s { margin-left: 25%; }
  .push--1-3--s, .push--2-6--s, .push--4-12--s { margin-left: 33.3333333333%; }
  .push--5-12--s { margin-left: 41.6666666667%; }
  .push--1-2--s, .push--2-4--s, .push--3-6--s, .push--6-12--s { margin-left: 50%; }
  .push--7-12--s { margin-left: 58.3333333333%; }
  .push--2-3--s, .push--4-6--s, .push--8-12--s { margin-left: 66.6666666667%; }
  .push--3-4--s, .push--9-12--s { margin-left: 75%; }
  .push--5-6--s, .push--10-12--s { margin-left: 83.3333333333%; }
  .push--11-12--s { margin-left: 91.6666666667%; }
  .span--omega--s { margin-left: auto; } }

/*
General layout width etc.
*/
.layout { margin: 0 auto; position: relative; outline: 0; }

/*
Small layout variant
*/
.layout--small { width: 60%; min-width: 768px; max-width: 1024px; padding: 0 4rem; }

@media screen and (max-width: 767px) { .layout--small { width: auto; min-width: 0; padding: 0 1.5rem; } }

/*
Normal layout variant
*/
.layout--normal { width: 80%; min-width: 1024px; max-width: 1024px; padding: 0 4rem; }

@media screen and (max-width: 1023px) { .layout--normal { width: auto; min-width: 768px; max-width: 1024px; } }

@media screen and (max-width: 767px) { .layout--normal { width: auto; min-width: 0; padding: 0 1.5rem; } }

/*
Wide layout variant
*/
.layout--wide { min-width: 1024px; max-width: 1280px; padding: 0 4rem; }

@media screen and (max-width: 1023px) { .layout--wide { width: auto; min-width: 768px; max-width: 1280px; } }

@media screen and (max-width: 767px) { .layout--wide { width: auto; min-width: 0; padding: 0 1.5rem; } }

@media screen and (max-width: 767px) { .layout--full-s { padding: 0; } }

.region { margin: 0 auto; padding: 6rem 0 9rem; clear: both; }

@media screen and (max-width: 1023px) { .region { padding: 4rem 0 6rem; } }

@media screen and (max-width: 767px) { .region { padding: 3rem 0 3rem; } }

.region:not(.region--alt) + .region:not(.region--alt) { padding-top: 0; }

.region--alt { background-color: #F9F9F9; }

.region:not(.region--alt) + .region--alt, .region--alt + .region:not(.region--alt) { padding-top: 9rem; }

@media screen and (max-width: 767px) { .region:not(.region--alt) + .region--alt, .region--alt + .region:not(.region--alt) { padding-top: 6rem; } }

.region--tiny { padding: 3rem 0; }

@media screen and (max-width: 767px) { .region--tiny { padding: 2rem 0; } }

@media screen and (max-width: 500px) { .region--tiny { padding: 1.5rem 0; } }

.region--small { padding: 4rem 0; }

@media screen and (max-width: 767px) { .region--small { padding: 3rem 0; } }

@media screen and (max-width: 500px) { .region--small { padding: 2rem 0; } }

.region--medium { padding: 8rem 0; }

@media screen and (max-width: 767px) { .region--medium { padding: 4rem 0; } }

@media screen and (max-width: 500px) { .region--medium { padding: 2rem 0; } }

.region--large { padding: 12rem 0; }

@media screen and (max-width: 1023px) { .region--large { padding: 8rem 0; } }

@media screen and (max-width: 767px) { .region--large { padding: 4rem 0; } }

.region--huge { padding: 16rem 0; }

@media screen and (max-width: 1023px) { .region--huge { padding: 12rem 0; } }

@media screen and (max-width: 767px) { .region--huge { padding: 8rem 0; } }

@media screen and (max-width: 500px) { .region--huge { padding: 4rem 0; } }

.region--bordered + .region--bordered { border-top: 1px solid #F9F9F9; }

.region--bordered + .region--bordered.region--alt { border-top-color: #fff; }

.region--no-space { padding: 0 !important; }

.region--no-space-bottom { padding-bottom: 0 !important; }

@media screen and (max-width: 767px) { .region--no-space-bottom--s { padding-bottom: 0 !important; } }

.region--no-space-top { padding-top: 0 !important; }

.hero-carousel { overflow: hidden; }

.hero-carousel__item { left: 0 !important; opacity: 0; transition: opacity 1s ease-in-out; width: 100%; }

.hero-carousel__item.is-selected { opacity: 1; }

.hero-carousel__item__img { width: 100%; }

.hero-carousel .flickity-slider { position: relative; transform: none !important; }

.hero-carousel .flickity-prev-next-button { display: none; }

.hero-carousel .flickity-page-dots { display: block; text-align: center; margin-top: 2.7rem; }

@media screen and (max-width: 767px) { .hero-carousel .flickity-page-dots { margin-top: 1rem; } }

.hero-carousel .flickity-page-dots .dot { display: inline-block; vertical-align: middle; width: .6rem; height: 1.2rem; background: #E5E6E7; margin: 0 .9rem; transition: background .2s ease-in-out; cursor: pointer; }

.hero-carousel .flickity-page-dots .dot:hover, .hero-carousel .flickity-page-dots .dot:focus { background: rgba(171, 163, 188, 0.5); }

.hero-carousel .flickity-page-dots .dot.is-selected { background: #474A4F; }

.hero-image__img { width: 100%; }

.modal { display: none; }

.modal.js-modal--open { display: block; }

.modal__backdrop { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255, 255, 255, 0.8); z-index: 1002; }

.modal__content { position: relative; z-index: 1003; background: white; width: 120rem; max-width: calc(100% - 3.5rem); padding: 5rem; max-height: calc(100% - 3.5rem); overflow: auto; box-shadow: 0 0 35px rgba(0, 0, 0, 0.1); }

.modal__content--high { height: 90vh; }

.modal__content--no-padding { padding: 0; }

@media screen and (max-width: 767px) { .modal__content { padding: 3rem; } }

@media screen and (max-width: 500px) { .modal__content { padding: 4rem 2rem; } }

.modal__content-wrapper { display: flex; flex-direction: column; justify-content: center; align-items: center; position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 1001; }

.modal__content__close { position: absolute; z-index: 1004; top: 1rem; right: 1rem; border: none; background: none; font-size: 1.4rem; padding: 1rem; cursor: pointer; color: #474A4F; }

.modal__content__close:hover, .modal__content__close:focus { color: black; }

.modal__content__header__title { display: block; text-align: center; }

.modal__content__body { display: block; margin: auto; }

.modal__content__body--small { max-width: 30rem; }

.modal__content__footer { margin-top: 3rem; text-align: center; }

.navigation { margin-top: 4rem; margin-bottom: 6rem; }

@media screen and (max-width: 767px) { .navigation { margin-top: 2rem; margin-bottom: 2rem; } }

.navigation__list { margin-bottom: 2.9rem; }

@media screen and (max-width: 1023px) { .navigation__list { margin-bottom: 2.1rem; } }

@media screen and (max-width: 767px) { .navigation__list { margin-top: 3rem; } }

.navigation__list__item { display: inline-block; vertical-align: middle; }

.navigation__cta { text-decoration: none; color: #474A4F; letter-spacing: .27rem; text-transform: uppercase; font-weight: 400; margin: 0 2rem; transition: opacity .2s ease-in-out; }

@media screen and (max-width: 1023px) { .navigation__cta { margin: 0 1rem; } }

@media screen and (max-width: 500px) { .navigation__cta { margin: 0 .5rem; letter-spacing: .1rem; } }

.navigation__cta:hover, .navigation__cta:focus { opacity: .7; }

.navigation__cta:before { content: ''; position: absolute; top: calc(100% + 1.5rem); left: 0; right: 0; margin: auto; width: .3rem; height: .7rem; background: #E5E6E7; opacity: 0; }

.navigation__cta:hover:before, .navigation__cta:focus:before { opacity: 1; top: calc(100% + 1rem); }

.navigation__cta--is-selected { position: relative; }

.navigation__cta--is-selected:before { content: ''; position: absolute; top: calc(100% + 1rem); left: 0; right: 0; margin: auto; width: .3rem; height: .7rem; background: #474A4F !important; opacity: 1; }

.navigation__logo { display: block; margin: auto; text-align: center; }

@media screen and (max-width: 1023px) { .navigation__logo { max-width: 25rem; } }

.navigation__logo__img { display: inline-block; max-width: 100%; }

.press-tile__img { max-width: 100%; }

.press-tile__cta { text-decoration: none; display: block; }

.press-tile__cta:hover, .press-tile__cta:focus { opacity: .7; }

.press-tile__title { margin-top: 2rem; line-height: 1.2; }

.press-tile__date { margin-top: .8rem; }

.prev-next-buttons { display: flex; justify-content: space-between; }

.prev-next-buttons__previous, .prev-next-buttons__next { display: flex; align-items: center; text-decoration: none; }

.prev-next-buttons__previous:hover, .prev-next-buttons__previous:focus, .prev-next-buttons__next:hover, .prev-next-buttons__next:focus { opacity: .7; }

.prev-next-buttons__next { text-align: right; }

.prev-next-buttons__previous .prev-next-buttons__icon { margin-right: 2rem; }

@media screen and (max-width: 1023px) { .prev-next-buttons__previous .prev-next-buttons__icon { margin-right: 3rem; } }

@media screen and (max-width: 767px) { .prev-next-buttons__previous .prev-next-buttons__icon { margin-right: 2rem; } }

.prev-next-buttons__next .prev-next-buttons__icon { margin-left: 2rem; }

@media screen and (max-width: 1023px) { .prev-next-buttons__next .prev-next-buttons__icon { margin-left: 3rem; } }

@media screen and (max-width: 767px) { .prev-next-buttons__next .prev-next-buttons__icon { margin-left: 2rem; } }

.prev-next-buttons__icon, .prev-next-buttons__value { line-height: 1; }

.prev-next-buttons__icon { font-size: 1.4rem; color: #474A4F; }

@media screen and (max-width: 767px) { .prev-next-buttons__icon { font-size: 1.2rem; } }

@media screen and (max-width: 767px) { .prev-next-buttons__value { font-size: 1.5rem !important; } }

.subtitle { font-size: 1.3rem; line-height: 1.3; color: #474A4F; font-weight: 500; text-transform: uppercase; letter-spacing: .05rem; }

.title { font-size: 3.8rem; line-height: 1.3; color: #474A4F; font-weight: 500; text-transform: uppercase; letter-spacing: .15rem; }

@media screen and (max-width: 1023px) { .title { font-size: 3.2rem; } }

@media screen and (max-width: 767px) { .title { font-size: 3rem; } }

@media screen and (max-width: 500px) { .title { font-size: 2.5rem; } }

.title--stroked { position: relative; z-index: 2; }

.title--stroked:before { content: ''; position: absolute; background: #979797; width: 13rem; height: .1rem; }

@media screen and (max-width: 767px) { .title--stroked:before { display: none; } }

.title--stroked--left:before { bottom: -.7rem; right: calc(100% - 2.75rem); }

.title--stroked--right:before { bottom: -.7rem; left: calc(100% - 2.75rem); }

.title--stroked--top:before { bottom: calc(100% + 5.5rem); left: 7.5rem; width: .1rem; height: 13rem; }

.title--small { font-size: 1.6rem; }

@media screen and (max-width: 767px) { .title--small { font-size: 1.4rem; } }

/* ---------------------------------------------------------------------------- Page templates ------------------------------------------------------------------------- */
.contact__address { font-size: 1.8rem; color: #474A4F; letter-spacing: .1rem; line-height: 1.8; text-transform: uppercase; }

.contact__phone { margin-top: 3rem; }

.contact__mail { margin-top: 3rem; }
