.hero-carousel {
  overflow: hidden;
  &__item {
    left: 0 !important;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    width: 100%;
    &.is-selected {
      opacity: 1;
    }
    &__img {
      width: 100%;
    }
  }

  .flickity-slider { 
    position: relative;
    transform: none !important; 
  }

  .flickity-prev-next-button {
    display: none;
  }
  .flickity-page-dots {
    display: block;
    text-align: center;
    margin-top: 2.7rem;
    @include respond-to(small) {
      margin-top: 1rem;
    }
    .dot {
      display: inline-block;
      vertical-align: middle;
      width: .6rem;
      height: 1.2rem;
      background: $c-grey-1;
      margin: 0 .9rem;
      transition: background .2s ease-in-out;
      cursor: pointer;
      &:hover,
      &:focus {
        background: rgba($c-secondary, .5);
      }
      &.is-selected {
        background: $c-primary;
      }
    }
  }
}