/* ----------------------------------------------------------------------------
 * Fonts
 * ------------------------------------------------------------------------- */

$f-primary    : 'Montserrat', sans-serif;
$f-secondary  : 'droid-serif', serif;
$f-icon				: 'mmb_iconfont';


/* ----------------------------------------------------------------------------
 * General variables
 * ------------------------------------------------------------------------- */

// Namespace
$ns: '' !default;

// Breakpoints
$bp-xsmall: 500px;
$bp-small: 767px;
$bp-medium: 1023px;
$bp-large: 1600px;

$bp-list: (
  l: (
    media: "large",
  ),
  m: (
    media: "medium",
  ),
  s: (
    media: "small",
  ),
  xs: (
    media: "xsmall",
  )
);

// page background
$page-bg: #fff;
$alt-region-bg: #F9F9F9;

// Page width
$page-min-width: 768px;
$page-max-width: 1024px;
$page-max-width-wide: 1280px;
$box-max-width: 1600px;
$page-padding: 30px;
$breakpoint: 1023px;
$breakpoint-jump-narrow: 1350px;

// Mobile
//$mobileRule: max-device-width;
$mobileRuleInverse: min-device-width;
$mobileRule: max-width;
$base-mobile-font-size: 1.5rem;
$base-mobile-line-size: 1.33;


// Grid settings
$grd-total-width: 100%;
$grd-gutter-width: 3%;
$grd-border-gutters: 1; //1 of 0
$grd-debug: 0;
$grd-debug-colors: 0;

$grda-columns: 6;
$grdb-columns: 4;

/* ----------------------------------------------------------------------------
 * Colors
 * ------------------------------------------------------------------------- */

$c-primary:       #474A4F;
$c-secondary:     #ABA3BC;

$c-black:         #000000;
$c-white:         #FFFFFF;

$c-facebook:      #3F5890;
$c-twitter:       #4DA4CD;
$c-instagram:     #AF417F;
$c-snapchat:      #F7F552;

$c-grey-1:        #E5E6E7;
$c-grey-2:        #888888;
$c-grey-3:        #979797;

/* ----------------------------------------------------------------------------
 * Spacing
 * ------------------------------------------------------------------------- */

$spacing-tiny: 1rem;
$spacing-small: 2rem;
$spacing-normal: 3rem;
$spacing-large: 6rem;
